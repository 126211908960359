<template>
  <div class="page">
    <v-container fluid>
      <scae-breadcrumbs></scae-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="2">
            <v-text-field
              label="CPF"
              v-model="form.filters.cpf"
              v-mask="'###.###.###-##'"
              outlined
              dense
              ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Nome de Usuário"
              v-model="form.filters.nomeCompleto"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              label="Perfil"
              :items="form.lists.listPerfis"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="form.filters.perfilId"
              outlined
              dense
              ></v-autocomplete>
            </v-col>
          <scae-select-ativo
            :cols="2"
            v-model="form.filters.ativo"
          ></scae-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <scae-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarUsuarios()"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterUsuario()"
            ></scae-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listUsuarios"
        item-key="id"
        :items-per-page="10"
        :footer-props="$footerProps"
        sort-by="nome"
        show-expand
      >
        <template v-slot:[`item.usuario`]="{ item }">
          <div class="hide-report">
            {{ item.cpf }}
          </div>
          <div class="report">
            {{ $esconderCpf(item.nomeDeUsuario) }}
          </div>
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.email`]="{ item }">
          {{ item.email }}
        </template>
        <template v-slot:[`item.perfis`]="{ item }">
          {{ item.perfis }}
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <scae-label-ativo :ativo="item.ativo"></scae-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <scae-tooltip
            :text="'Visualizar Usuario'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarUsuario(item)"
          ></scae-tooltip>
          <scae-tooltip
            :text="'Editar Usuario'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterUsuario(item)"
          ></scae-tooltip>
          <scae-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Usuario`"
            :icon="`${item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'}`"
            :color="item.ativo ? 'blue' : 'red'"
            :small="true"
            @click="inativarAtivarUsuario(item)"
          ></scae-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="perfis" :colspan="headers.length">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                class="chip-permissao"
                v-for="(perfil, i) in item.perfis"
                :key="i"
              >
                <v-icon class="perfil" small>mdi-account-multiple</v-icon>
                {{ perfil.nome }}
              </v-chip>
            </v-chip-group>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarUsuarioDialog
      :visible="dialogManterUsuario.visible"
      :content="dialogManterUsuario.content"
      :edit="dialogManterUsuario.edit"
      @onClose="dialogManterUsuario.close()"
      @onSave="buscarUsuarios()"
    ></CadastrarEditarUsuarioDialog>
    <VisualizarUsuarioDialog
      :visible="dialogVisualizarUsuario.visible"
      :content="dialogVisualizarUsuario.content"
      @onClose="dialogVisualizarUsuario.close()"
    ></VisualizarUsuarioDialog>
  </div>
</template>
  
<script>
  import pageLoadingMixin from "mixins/page-loading";
  import { Dialog, Usuario } from "~/commons/classes";
  import CadastrarEditarUsuarioDialog from "../../administracao/gerenciar-usuario/dialogs/CadastrarEditarUsuarioDialog.vue";
  import VisualizarUsuarioDialog from "../../administracao/gerenciar-usuario/dialogs/VisualizarUsuarioDialog.vue";
  
  export default {
    name: "ManterUsuariosView",
    components: {
      CadastrarEditarUsuarioDialog,
      VisualizarUsuarioDialog,
    },
    mixins: [pageLoadingMixin],
    data: () => ({
      dialogManterUsuario: Dialog.Build(),
      dialogVisualizarUsuario: Dialog.Build(),
      form: {
        filters: {
          id: null,
          nomeCompleto: null,
          cpf: null,
          nomeDoPerfil: null,
          ano: null,
          semestre: null,
          perfis: null,
          ativo: null,
        },
        lists: {
          listGrupos: [],
          listPerfis: [],
          listUsuarios: []
        },
      },
      headers: [
        { text: "CPF", align: "center", value: "usuario" },
        { text: "Nome de Usuário", align: "center", value: "nome" },
        { text: "E-mail", align: "center", value: "email" },
        { text: "Perfil", align: "center", value: "data-table-expand" },
        { text: "Ativo", align: "center", value: "ativo" },
        { text: "Ações", align: "center", value: "acoes", sortable: false },
      ],
    }),
    methods: {
    openDialogManterUsuario(usuario) {
      usuario
        ? this.$visualizarUsuario(usuario.id).then(
          ({ data }) => (this.dialogManterUsuario.open(new Usuario({
            ...usuario,
            ...data.usuario,
          }).format(), true)))
        : this.dialogManterUsuario.open(Usuario.Build());
    },
    openDialogVisualizarUsuario(usuario) {
      this.$visualizarUsuario(usuario.id).then(
        ({ data }) => (this.dialogVisualizarUsuario.open(new Usuario(data.usuario)))
      );
    },
    buscarUsuarios() {
      if (this.$validarFormulario()) {
        this.showPageLoading();
        this.form.filters.cpf = this.$unMask(this.form.filters.cpf); 
        this.$obterUsuario({ params: this.form.filters }).then(
          ({ data }) => {
            this.form.lists.listUsuarios = data.usuarios;
            this.hidePageLoading();
          }).catch(() => this.hidePageLoading());
      }
    },
      
    inativarAtivarUsuario(usuario) {
      if (usuario.usuarioSadRh) {
        this.notifyError("Não é permitido inativar o usuário");
        return;
      }

      this.$swal({
        text: `Deseja confirmar a ${
          usuario.ativo ? "inativação" : "ativação"
        } do Usuário ${usuario.nome}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarUsuario(
          { usuarioId: usuario.id, ativo: !usuario.ativo, nomeDeUsuario: usuario.nomeDeUsuario },
          usuario.id
        ).then(({ data }) => {
          this.notifySuccess(`${data.mensagem}<br/><i>Para consolidar as alterações, é necessário sair e entrar novamente no sistema!</i>`);
          this.buscarUsuarios();
        });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listUsuarios = [];
      this.form.filters = {
        nome: null,
        cpf: null,
        nomeDoPerfil: null,
        perfis: null,
        ativo: null,
      };
    }, 
  },
  mounted() {
    this.$obterPerfis({ params : { ativo: true } }).then(({ data }) => {
      this.form.lists.listPerfis = data.perfis;
    });
  }
};
  </script>
  <style scoped>
  .perfis {
    padding: 1em !important;
    text-align: center;
    font-weight: 500;
  }
  .perfil {
    padding-right: 0.5em;
  }
  </style>