import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"persistent":"","width":"800"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,{staticClass:"not-margin pt-0 pr-0 pl-0"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c('div',{staticClass:"text-center"},[_c(VIcon,[_vm._v("mdi-eye")]),_vm._v(" Visualizar Oferta de Ensino ")],1)]),_c('div',{staticClass:"container dialog"},[_c(VRow,{staticClass:"d-flex justify-center mt-7"},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"value":_vm.content.etapa ? _vm.content.etapa[0].nome : '',"label":"Etapas","readonly":"","outlined":"","dense":""}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"value":_vm.content.modalidade ? _vm.content.modalidade[0].nome : '',"label":"Modalidade","readonly":"","outlined":"","dense":""}})],1)],1),_c(VRow,{staticClass:"d-flex justify-center mt-7"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Ano/Série/Módulo/Fase*","counter":"252","outlined":"","dense":"","readonly":"","rules":[_vm.$required, function (v) { return _vm.$max(v, 252); }]},model:{value:(_vm.content.nome),callback:function ($$v) {_vm.$set(_vm.content, "nome", $$v)},expression:"content.nome"}})],1)],1),_c('div',{staticClass:"btn-group text-center"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('onClose')}}},[_c(VIcon,[_vm._v("mdi-close")]),_vm._v(" Fechar ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }