<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon> Visualizar Mantenedor
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="12">
              <v-text-field
                :value="content.nome"
                label="Nome do Mantenedor"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="8">
              <v-text-field
                :value="$formatCpfCnpj(content.cpfCnpj)"
                label="CPF/CNPJ"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="content.qualificacaoNome"
                label="Qualificação"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="btn-group text-center">
            <v-btn color="primary" @click="$emit('onClose')">
              <v-icon>mdi-close</v-icon>
              Fechar
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
  import { DialogProps, Mantenedores } from '~/commons/classes';
  
  export default {
    props: new DialogProps(Mantenedores)
  };
</script>
