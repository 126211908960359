<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon> Visualizar Oferta de Ensino
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="6">
              <v-text-field
                :value="content.etapa ? content.etapa[0].nome : ''"
                label="Etapas"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="content.modalidade ? content.modalidade[0].nome : ''"
                label="Modalidade"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mt-7">
            <v-col cols="12">
              <v-text-field
                v-model="content.nome"
                label="Ano/Série/Módulo/Fase*"
                counter="252"
                outlined
                dense
                readonly
                :rules="[$required, (v) => $max(v, 252)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="btn-group text-center">
            <v-btn color="primary" @click="$emit('onClose')">
              <v-icon>mdi-close</v-icon>
              Fechar
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, OfertaDeEnsino } from '~/commons/classes';

export default {
  props: new DialogProps(OfertaDeEnsino),
};
</script>
