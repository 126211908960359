<template>
  <div class="page">
    <v-container fluid>
      <scae-breadcrumbs></scae-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
              <v-col cols="2">
                <v-text-field
                label="Mantenedor"
                v-model="form.filters.nome"
                outlined
                dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
              <v-text-field
              label="CPF/CNPJ"
              v-model="form.filters.cpf"
              outlined
              dense
              v-mask="getMask(form.filters.cpf)"
              ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select
              label="Situação"
              :items="opcoesStatus"
              v-model="form.filters.situacao"
              item-text="descricao"
              item-value="value"
              id="situacao"
              dense
              outlined
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <scae-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarRelatorios()"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></scae-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5 printable"
        :headers="getFilteredHeader(headersAnalitico)"
        :items="form.lists.listRelatorios"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps"
        >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.cpfCnpj`]="{ item }">
          <div class="report">
            {{ $formatCpfCnpjSemEsconder(item.cpfCnpj) }}
          </div>
        </template>
        <template v-slot:[`item.situacao`]="{ item }">
          <scae-ativo-inativo :ativo="item.situacao"></scae-ativo-inativo>
        </template>
        <template class="report" v-slot:[`item.nomeUsuarioAcao`]="{ item }">
          {{ item.nomeUsuarioAcao }}
        </template>
        <template v-slot:[`item.dataHoraDaAcao`]="{ item }">
          {{ $formatarDataHora(item.dataHoraDaAcao) }}
        </template>
        <template v-slot:[`item.acao`]="{ item }">
          {{ item.acao }}
        </template>
      </v-data-table>
      <scae-buttons
        v-if="form.lists.listRelatorios.length"
          :actionText="'Baixar PDF'"
          :actionIcon="'mdi-printer'"
          :cancelText="'Baixar CSV'"
          :cancelIcon="'mdi-file'"
          @click="baixarRelatorioPdf()"
          @cancel="baixarCSVRelatorio()"
        ></scae-buttons>
    </v-container>
  </div>
</template>
  
<script>
  import pageLoadingMixin from "mixins/page-loading";

  export default {
    name: "ListarRelatoriosLogMantenedoresView",
    mixins: [pageLoadingMixin],

    data: () => ({
      onReport: false,
      componentKey: 0,
      form: {
        filters: {
          nome: null,
          cpf: null,
          situacao: null,
        },
        lists: {
          listRelatorios: [],
        },
        itemsPerPage: 10,
        geradorRelatorio: null,
        horaDaGeracao: null,
        totalMantenedores: 0
      },
      opcoesStatus: [
        { value: true, descricao: "Ativo" },
        { value: false, descricao: "Inativo" }
      ],
      headersAnalitico: [
        { text: "Mantenedor", align: "center", value: "nome" },
        { text: "CPF/CNPJ", align: "center", value: "cpfCnpj" },
        { text: "Situação", align: "center", value: "situacao" },
        { text: "Usuário da Ação", align: "center", value: "nomeUsuarioAcao", onlyReport: true },
        { text: "Data/Hora da ação", align: "center", value: "dataHoraDaAcao" },
        { text: "Ação", align: "center", value: "acao" }
      ],
    }),
    methods: {
      getFilteredHeader(headers) {
        return headers.filter((header) => {
          if (header.onlyView) return !this.onReport;
          if (header.onlyReport) return this.onReport;
          return true;
        });
      },
      buscarRelatorios() {
        if (this.$validarFormulario()) {
          this.form.filters.cpf = this.$unMask(this.form.filters.cpf);
          const preparedRequest = JSON.parse(JSON.stringify(this.form.filters));
          const buscarRelatorio = (params) => {
            return this.$obterRelatorioLogMantenedores(params);
          };

          this.showPageLoading();
          try {
            buscarRelatorio(this.$montarParamsFilters(preparedRequest))
              .then(
                ({ data }) => {
                  this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
                  this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
                  this.form.totalMantenedores =
                    data.relatorio.totalMantenedores;
                  this.form.lists.listRelatorios = data.relatorio.logMantenedores;
                }
              ).then(() => this.hidePageLoading());
          } catch {
            this.hidePageLoading();
          }
        }
      },
      baixarCSVRelatorio() {
        const baixarRelatorio = (params) => {
          return this.$obterRelatorioLogCSVMantenedores(params);
        };

        this.showPageLoading();

        try {
          baixarRelatorio(this.$montarParamsFilters(this.form.filters))
            .then(
              ({ data }) => this.$baixarRelatorioCSV(data, `SCAE.RelatórioLogDeMantenedorCSV`)
            )
            .then(() => this.hidePageLoading());
        } catch {
          this.hidePageLoading();
        }
      },
      baixarRelatorioPdf() {
        const baixarPdf = (params) => {
          return  this.$obterRelatorioLogPDFMantenedores({
            ...params,
            responseType: "blob"
          });
        };

        this.showPageLoading();

        try {
          baixarPdf(this.$montarParamsFilters(this.form.filters))
          .then(({data}) => {
            const url = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'SCAE.RelatórioLogMantenedor.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .then(() => this.hidePageLoading());
        } catch {
          this.hidePageLoading();
        }
      },
      resetForm() {
        this.$resetForm();
        this.form.lists.listRelatorios = [];
      },
      getMask(cpf) {
            if (cpf) {
            const numericCpf = cpf.replace(/\D/g, '');
            return numericCpf.length === 11 ? '###.###.###-##' : '##.###.###/####-##';
            }
            return '';
        },
        validacaoCpfCnpj(v) {
          const cleanedValue = v.replace(/\D/g, '');
          const digitCount = cleanedValue.length;

          // Verificar se o CPF/CNPJ tem a quantidade mínima de dígitos
          if (digitCount < 11) {
            return 'CPF/CNPJ inválido';
          }

          // Verificar se todos os dígitos são iguais, o que indica um número inválido
          if (/^(\d)\1+$/.test(cleanedValue)) {
            return 'CPF/CNPJ inválido';
          }

          // Verificar se é um CPF válido
          if (digitCount === 11 && !this.$validateCpf(cleanedValue)) {
            return 'CPF inválido';
          }

          // Verificar se é um CNPJ válido
          if (digitCount === 14 && !this.$validateCnpj(cleanedValue)) {
            return 'CNPJ inválido';
          }

          // Se passou por todas as verificações, é válido
          return true;
        },
    },
  };
</script>
