<template>
  <div class="page">
    <v-container fluid>
      <scae-breadcrumbs></scae-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-text-field
              label="Mantenedores"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
              <v-text-field
              label="CPF/CNPJ"
              v-model="form.filters.cpfCnpj"
              outlined
              dense
              v-mask="getMask(form.filters.cpfCnpj)"
              ></v-text-field>
          </v-col>
          <scae-select-ativo
            :cols="3"
            v-model="form.filters.ativo"
          ></scae-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <scae-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarMantenedores()"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterMantenedores()"
            ></scae-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listaMantendedores"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.cpfCnpj`]="{ item }">
          <div class="report">
              {{ $formatCpfCnpj(item.cpfCnpj) }}
          </div>
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <scae-label-ativo :ativo="item.ativo"></scae-label-ativo>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <scae-tooltip
            :text="'Visualizar Mantenedor'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarMantenedores(item)"
          ></scae-tooltip>
          <scae-tooltip
            :text="'Editar Mantenedor'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterMantenedores(item)"
          ></scae-tooltip>
          <scae-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Mantenedor`"
            :icon="item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'"
            :color="item.ativo ? 'blue' : 'red'"
            :small="true"
            @click="inativarAtivarMantenedores(item)"
          ></scae-tooltip>
        </template>
      </v-data-table>
    </v-container>
      <CadastrarEditarMantenedoresDialog
      :visible="dialogManterMantenedores.visible"
      :content="dialogManterMantenedores.content"
      :edit="dialogManterMantenedores.edit"
      @onClose="dialogManterMantenedores.close()"
      @onSave="buscarMantenedores()"/>
    <VisualizarMantenedoresDialog
      :visible="dialogVisualizarMantenedores.visible"
      :content="dialogVisualizarMantenedores.content"
      @onClose="dialogVisualizarMantenedores.close()"/>
  </div>
</template>

<script>
import { Dialog, Mantenedores } from "~/commons/classes";
import CadastrarEditarMantenedoresDialog from "./dialogs/CadastrarEditarMantenedoresDialog.vue";
import VisualizarMantenedoresDialog from "./dialogs/VisualizarMantenedoresDialog.vue";

export default {
  name: "ManterMantenedoresView",
  components: {
    CadastrarEditarMantenedoresDialog,
    VisualizarMantenedoresDialog,
  },
  data: () => ({
    dialogManterMantenedores: Dialog.Build(),
    dialogVisualizarMantenedores: Dialog.Build(),
    form: {
      filters: {
        nome: null,
        ativo: null,
        qualificacao: null,
      },
      lists: {
          listaMantendedores: []
      }
    },
    headers: [
        { text: "Mantenedores", align: "center", value: "nome" },
        { text: "CPF/CNPJ", align: "center", value: "cpfCnpj" },
        { text: "Ativo", align: "center", value: "ativo" },
        { text: "Ações", align: "center", value: "acoes", sortable: false },
    ]
  }),
  methods: {
    getMask(valor) {
        if (valor) {
          const valorNumerico = valor.replace(/\D/g, '');
          return valorNumerico.length === 11 ? '###.###.###-##' : '##.###.###/####-##';
        }
        return '';
    },
    openDialogManterMantenedores(mantenedor) {
      mantenedor
        ? this.$visualizarMantenedor(mantenedor.id).then(
          ({ data }) => this.dialogManterMantenedores.open(new Mantenedores(data.obterMantendedores).format(), true))
        : this.dialogManterMantenedores.open(Mantenedores.Build());
    },
    openDialogVisualizarMantenedores(mantenedor) {
      mantenedor 
        ? this.$visualizarMantenedor(mantenedor.id).then(
          ({ data }) => this.dialogVisualizarMantenedores.open(new Mantenedores(data.obterMantendedores)))
        : this.dialogVisualizarMantenedores.open(Mantenedores.Build());
    },

    buscarMantenedores() {
      const cpfCnpjLimpo = this.limparFormatacao(this.form.filters.cpfCnpj || '');
      this.$obterMantenedor({ params: { ...this.form.filters, cpfCnpj: cpfCnpjLimpo } }).then(
        ({ data }) => (this.form.lists.listaMantendedores = data.listaMantendedores)
      );
    },
    limparFormatacao(valor) {
        return valor.replace(/[^\d]/g, '');
    },
    inativarAtivarMantenedores(mantenedor) {
        this.$swal({
          text: `Deseja confirmar a ${
              mantenedor.ativo ? "inativação" : "ativação"
          } do Mantenedor ${mantenedor.nome}?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
          }).then(() => {
          this.$inativarAtivarMantenedor(
              { mantenedorId: mantenedor.id, ativo: !mantenedor.ativo },
              mantenedor.id
          ).then(({ data }) => {
              this.notifySuccess(data.mensagem);
              this.buscarMantenedores();
          });
        });
    },
    resetForm() {
        this.$resetForm();
        this.form.lists.listaMantendedores = [];
    },
  },
};
</script>