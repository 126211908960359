<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-eye</v-icon> Visualizar Unidade de Ensino
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-tabs v-model="selectedTab">
              <v-tab key="#identificacao">Identificação</v-tab>
              <v-tab key="#endereco">Endereço</v-tab>
              <v-tab key="#mantenedores">Mantenedor</v-tab>
              <v-tab key="#portaria">Portaria</v-tab>

              <v-tab-item key="#identificacao">
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="4">
                    <v-text-field
                      v-model="content.codigoMec"
                      label="Código MEC"
                      counter="8"
                      outlined
                      readonly
                      dense
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="content.codigo"
                      label="Cadastro Escolar"
                      outlined
                      dense
                      readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="content.nomeUnidadeEnsino"
                      label="Unidade de Ensino*"
                      counter="70"
                      readonly
                      outlined
                      dense
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="4">
                    <v-text-field
                      v-model="content.cnpj"
                      label="CNPJ*"
                      v-mask="'##.###.###/####-##'"
                      counter="18"
                      readonly
                      outlined
                      dense
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="content.nomeEmpresarial"
                      label="Nome Empresarial*"
                      counter="100"
                      outlined
                      readonly
                      dense
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Dependência Adm.*"
                      v-model="content.dependenciaAdministrativa.nome"
                      outlined
                      dense
                      readonly
                      return-object
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="4">
                    <v-text-field
                      label="Sit. Funcionamento*"
                      v-model="content.situacaoFuncionamento.nome"
                      outlined
                      readonly
                      dense
                      return-object
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Unidade Regional*"
                      v-model="content.unidadeRegional.nome"
                      outlined
                      dense
                      readonly
                      return-object
                      ></v-text-field>
                    </v-col>
                  <v-col cols="4">
                    <v-textarea
                      label="Identificação*"
                      v-model="identificacaoNomes"
                      outlined
                      readonly
                      dense
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item key="#endereco">
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="3">
                    <v-text-field
                      v-model="content.cep"
                      label="CEP*"
                      counter="9"
                      outlined
                      v-mask="'#####-###'"
                      dense
                      readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="content.logradouro"
                      label="Logradouro*"
                      counter="70"
                      outlined
                      readonly
                      dense
                      ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="content.numero"
                      label="Número*"
                      counter="5"
                      outlined
                      readonly
                      dense
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  
                  <v-col cols="6">
                    <v-text-field
                      v-model="content.bairro"
                      label="Bairro*"
                      counter="50"
                      outlined
                      readonly
                      dense
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="content.complemento"
                      label="Complemento*"
                      counter="50"
                      outlined
                      readonly
                      dense
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="4">
                    <v-text-field
                      label="Município*"
                      v-model="content.municipio.nome"
                      outlined
                      readonly
                      dense
                      return-object
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="UF*"
                      v-model="content.uf"
                      outlined
                      dense
                      return-object
                      readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Localização*"
                      v-model="content.localizacao.nome"
                      outlined
                      dense
                      readonly
                      return-object
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item key="#mantenedores">
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="6">
                    <v-text-field
                      label="Mantenedor"
                      outlined
                      dense
                      readonly
                      disabled
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="CPF/CNPJ"
                      outlined
                      dense
                      readonly
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-data-table
                  class="mt-5"
                  :headers="headers"
                  :items="content.relacaoMantenedores"
                  :items-per-page="10"
                  :footer-props="$footerProps"
                >
                  <template v-slot:[`item.nomeMantenedor`]="{ item }">
                    {{ item.nomeMantenedor  }}
                  </template>
                  <template v-slot:[`item.cpf`]="{ item }">
                    <div class="report">
                        {{ $formatCpfCnpj(item.cpfCnpjMantenedor) }}
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item key="#portaria">
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="6">
                    <v-text-field
                      label="Portaria"
                      type="number"
                      readonly
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                    <scae-date-picker
                      label="Data DO"
                      cols="6"
                      :disabled="true"
                  ></scae-date-picker>
              </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="6">
                    <v-text-field
                      label="SEI"
                      readonly
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Finalidade"
                      readonly
                      outlined
                      dense
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="12">
                    <v-text-field
                      label="Oferta de Ensino"
                      outlined
                      readonly
                      dense
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-data-table
                  class="mt-5"
                  :headers="headersPortaria"
                  :items="content.portarias"
                  :items-per-page="10"
                  :footer-props="$footerProps"
                >
                  <template v-slot:[`item.numeroPortaria`]="{ item }">
                    {{ item.numeroPortaria }}
                  </template>
                  <template v-slot:[`item.dataDo`]="{ item }">
                    {{ item.dataDo | date }} 
                  </template>
                  <template v-slot:[`item.sei`]="{ item }">
                      {{ $formatSEI(item.sei) }}
                    </template>
                    <template v-slot:[`item.finalidade`]="{ item }">
                    {{ mapearIdsParaNomes(item.finalidades, form.lists.listFinalidades) }}
                  </template>
                  <template v-slot:[`item.ofertaDeEnsino`]="{ item }">
                    {{ mapearIdsParaNomes(item.ofertasEnsino, form.lists.listOfertaEnsino) }}
                  </template>
                </v-data-table>
              </v-tab-item>

            </v-tabs>
          </v-form>
          <div class="btn-group text-center">
            <v-btn color="primary" @click="fechar()">
              <v-icon>mdi-close</v-icon>
              Fechar
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DialogProps, UnidadeDeEnsino } from '~/commons/classes';

export default {
  props: new DialogProps(UnidadeDeEnsino),
  data: () => ({
    form: {
    lists: {
      listFinalidades: [],
      listOfertaEnsino: [],
    },
  },
  headers: [
        { text: "Mantenedor", align: "center", value: "nomeMantenedor" },
        { text: "CPF/CNPJ", align: "center", value: "cpf" },
    ],
    headersPortaria: [
        { text: "Portaria", align: "center", value: "numeroPortaria" },
        { text: "Data DO", align: "center", value: "dataDo" },
        { text: "SEI", align: "center", value: "sei" },
        { text: "Finalidade", align: "center", value: "finalidade" },
        { text: "Oferta de Ensino", align: "center", value: "ofertaDeEnsino" },
    ],
    searchInput: null,
    visibleDialog: false,
    selectedTab: '#identificacao'
  }),

  computed: {
    identificacaoNomes() {
      if (!this.content.identificacoes || !Array.isArray(this.content.identificacoes)) {
        return '';
      }

      return this.content.identificacoes.map(i => i.nome).join(', ');
    },
  },

  methods: {
    mapearIdsParaNomes(ids, lista) {
        if (!ids || !lista || !Array.isArray(ids) || !Array.isArray(lista)) {
          return '';
        }
        const nomes = ids.map(id => {
          const item = lista.find(item => item.id === id);
          return item ? item.nome : null;
        });

        return nomes.join(', ');
      },
      obterAtualizarOfertaEnsino() {
          this.$buscarOfertasEnsinos().then(({ data }) => {
            this.form.lists.listOfertaEnsino  = data.ofertasDeEnsino;
          });
      },
      obterAtualizarFinalidades() {
        this.$obterFinalidades().then(({ data }) => {
          this.form.lists.listFinalidades  = data.finalidades;
        });
      },
      fechar() {
        this.selectedTab = '#identificacao';
        this.$emit('onClose');
      }
  },
  mounted() {
      this.obterAtualizarFinalidades();
      this.obterAtualizarOfertaEnsino();
      console.warn = () => {};
  }
}
</script>
