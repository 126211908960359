import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c(VContainer,{attrs:{"fluid":""}},[_c('scae-breadcrumbs'),_c(VForm,{ref:"form"},[_c(VRow,{staticClass:"d-flex justify-center mt-3"},[_c(VCol,{attrs:{"cols":"2"}},[_c(VTextField,{attrs:{"label":"Mantenedor","outlined":"","dense":""},model:{value:(_vm.form.filters.nome),callback:function ($$v) {_vm.$set(_vm.form.filters, "nome", $$v)},expression:"form.filters.nome"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getMask(_vm.form.filters.cpf)),expression:"getMask(form.filters.cpf)"}],attrs:{"label":"CPF/CNPJ","outlined":"","dense":""},model:{value:(_vm.form.filters.cpf),callback:function ($$v) {_vm.$set(_vm.form.filters, "cpf", $$v)},expression:"form.filters.cpf"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VSelect,{attrs:{"label":"Situação","items":_vm.opcoesStatus,"item-text":"descricao","item-value":"value","id":"situacao","dense":"","outlined":"","hide-details":"auto"},model:{value:(_vm.form.filters.situacao),callback:function ($$v) {_vm.$set(_vm.form.filters, "situacao", $$v)},expression:"form.filters.situacao"}})],1)],1),_c('div',{staticClass:"text-center"},[_c(VCol,{attrs:{"cols":"12"}},[_c('scae-tooltip',{attrs:{"text":'Buscar',"icon":'mdi-magnify'},on:{"click":function($event){return _vm.buscarRelatorios()}}}),_c('scae-tooltip',{attrs:{"text":'Limpar',"icon":'mdi-eraser'},on:{"click":function($event){return _vm.resetForm()}}})],1)],1)],1),_c(VDataTable,{staticClass:"mt-5 printable",attrs:{"headers":_vm.getFilteredHeader(_vm.headersAnalitico),"items":_vm.form.lists.listRelatorios,"items-per-page":_vm.form.itemsPerPage,"footer-props":_vm.$footerProps},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome)+" ")]}},{key:"item.cpfCnpj",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"report"},[_vm._v(" "+_vm._s(_vm.$formatCpfCnpjSemEsconder(item.cpfCnpj))+" ")])]}},{key:"item.situacao",fn:function(ref){
var item = ref.item;
return [_c('scae-ativo-inativo',{attrs:{"ativo":item.situacao}})]}},{key:"item.nomeUsuarioAcao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomeUsuarioAcao)+" ")]}},{key:"item.dataHoraDaAcao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatarDataHora(item.dataHoraDaAcao))+" ")]}},{key:"item.acao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.acao)+" ")]}}],null,true)}),(_vm.form.lists.listRelatorios.length)?_c('scae-buttons',{attrs:{"actionText":'Baixar PDF',"actionIcon":'mdi-printer',"cancelText":'Baixar CSV',"cancelIcon":'mdi-file'},on:{"click":function($event){return _vm.baixarRelatorioPdf()},"cancel":function($event){return _vm.baixarCSVRelatorio()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }