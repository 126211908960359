import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"persistent":"","width":"800"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,{staticClass:"not-margin pt-0 pr-0 pl-0"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c('div',{staticClass:"text-center"},[_c(VIcon,[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.edit ? "Editar" : "Cadastrar")+" FAQ ")],1)]),_c('div',{staticClass:"container dialog"},[(_vm.visible)?_c(VForm,{ref:"form"},[_c(VRow,{staticClass:"d-flex justify-center mt-7"},[_c(VCol,{attrs:{"cols":"8"}},[_c(VTextField,{attrs:{"label":"Pergunta","counter":"150","outlined":"","dense":"","rules":[
                  function (v) { return _vm.$required(v); },
                  function (v) { return _vm.$max(v, 150); },
                  function (v) { return _vm.$min(v, 10); } ]},model:{value:(_vm.content.pergunta),callback:function ($$v) {_vm.$set(_vm.content, "pergunta", $$v)},expression:"content.pergunta"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Posição","type":"number","outlined":"","dense":"","rules":[function (v) { return _vm.$required(v); }, function (v) { return _vm.$minValue(v, 1); }]},model:{value:(_vm.content.posicao),callback:function ($$v) {_vm.$set(_vm.content, "posicao", $$v)},expression:"content.posicao"}})],1)],1),_c(VRow,{staticClass:"d-flex justify-center mt-7"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":"Resposta","counter":"1000","outlined":"","dense":"","rules":[
                  function (v) { return _vm.$required(v); },
                  function (v) { return _vm.$max(v, 1000); },
                  function (v) { return _vm.$min(v, 10); } ]},model:{value:(_vm.content.resposta),callback:function ($$v) {_vm.$set(_vm.content, "resposta", $$v)},expression:"content.resposta"}})],1)],1),_c(VRow,{staticClass:"d-flex justify-center mt-7"},[_c(VCombobox,{attrs:{"chips":"","label":"Palavras-Chave","hint":"Informe as palavras chave para essa Pergunta/Resposta","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var attrs = ref.attrs;
                var item = ref.item;
                var select = ref.select;
                var selected = ref.selected;
return [_c(VChip,_vm._b({attrs:{"close":"","input-value":selected},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-key")]),_vm._v(" "+_vm._s(item)+" ")],1)])]}}],null,false,3143207623),model:{value:(_vm.content.palavrasChave),callback:function ($$v) {_vm.$set(_vm.content, "palavrasChave", $$v)},expression:"content.palavrasChave"}})],1)],1):_vm._e()],1),_c('scae-buttons',{attrs:{"actionText":'Salvar'},on:{"click":function($event){return _vm.salvar()},"cancel":function($event){return _vm.$emit('onClose')}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }