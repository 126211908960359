<template>
    <div class="page">
      <v-container fluid>
        <scae-breadcrumbs></scae-breadcrumbs>
        <v-form ref="form">
          <v-row class="d-flex justify-center mt-3">
            <v-col cols="2">
              <v-text-field
                label="Mantenedor (Nome do Mantenedor)"
                v-model="form.filters.nome"
                outlined
                dense
                ></v-text-field>
            </v-col>
            <v-col cols="3" v-show="form.filters.analitico">
              <v-text-field
                label="CPF/CNPJ"
                v-model="form.filters.cpf"
                outlined
                dense
                v-mask="getMask(form.filters.cpf)"
                ></v-text-field>
            </v-col>
            <v-col cols="2" v-show="form.filters.analitico">
              <v-select
                label="Situação"
                :items="opcoesStatus"
                v-model="form.filters.situacao"
                item-text="descricao"
                item-value="value"
                id="situacao"
                dense
                outlined
                hide-details="auto"
              ></v-select>
            </v-col>
            <scae-switch-button
              v-model="form.filters.analitico"
              :cols="2"
              label="Tipo de Relatório"
              labelChecked="Analítico"
              labelUnchecked="Sintético"
              @click="resetForm()"
            ></scae-switch-button>
          </v-row>
          <div class="text-center">
            <v-col cols="12">
              <scae-tooltip
                :text="'Buscar'"
                :icon="'mdi-magnify'"
                @click="buscarRelatorios()"
              ></scae-tooltip>
              <scae-tooltip
                :text="'Limpar'"
                :icon="'mdi-eraser'"
                @click="resetForm()"
              ></scae-tooltip>
            </v-col>
          </div>
        </v-form>
        <!--Se o resultado da consulta de relatório for analítico-->
        <v-data-table
          v-show="form.filters.analitico"
          class="mt-5 printable"
          :headers="getFilteredHeader(headersAnalitico)"
          :items="form.lists.listRelatorios"
          :items-per-page="form.itemsPerPage"
          :footer-props="$footerProps"
          >
          <template v-slot:[`item.situacao`]="{ item }">
            <scae-ativo-inativo :ativo="item.situacao"></scae-ativo-inativo>
          </template>
          <template v-slot:[`item.nomeMantenedor`]="{ item }">
            {{ item.nomeMantenedor }}
          </template>
          <template v-slot:[`item.cpfMantenedor`]="{ item }">
            <div class="report">
                {{ $formatCpfCnpjSemEsconder(item.cpfMantenedor) }}
            </div>
         </template>
        </v-data-table>

        <v-data-table
          v-show="!form.filters.analitico"
          class="mt-5 printable"
          :headers="getFilteredHeader(headersSintetico)"
          :items="form.lists.listRelatorios"
          :items-per-page="form.itemsPerPage"
          :footer-props="$footerProps"
        >
          <template v-slot:[`item.nome`]="{ item }">
            {{ item.nomeProfissional }}
          </template>
          <template v-slot:[`item.totalMantenedores`]="{ item }">
            {{ item.totalMantenedores }}
          </template>
        </v-data-table>
        <scae-buttons
          v-if="form.lists.listRelatorios.length > 0"
          :actionText="'Baixar PDF'"
          :actionIcon="'mdi-printer'"
          :cancelText="'Baixar CSV'"
          :cancelIcon="'mdi-file'"
          @click="baixarRelatorioPdf()"
          @cancel="baixarRelatorioCsv()"
        ></scae-buttons>
      </v-container>
    </div>
  </template>
  
<script>
  import pageLoadingMixin from "mixins/page-loading";

  export default {
    name: "ListarRelatoriosMantenedoresView",
    mixins: [pageLoadingMixin],
    data: () => ({
      onReport: false,
      componentKey: 0,
      form: {
        filters: {
          nome: null,
          cpf: null,
          situacao: null,
          analitico: true,
        },
        lists: {
          listRelatorios: [],
        },
        itemsPerPage: 10,
        geradorRelatorio: null,
        horaDaGeracao: null,
        totalMantenedores: 0,
        mostrarAnalitico: true,
      },
      opcoesStatus: [
        { value: true, descricao: "Ativo" },
        { value: false, descricao: "Inativo" },
      ],
      headersAnalitico: [
        { text: "Mantenedor", align: "center", value: "nomeMantenedor"},
        { text: "CPF/CNPJ", align: "center", value: "cpfMantenedor"},
        { text: "Situação", align: "center", value: "situacao"},
      ],
      headersSintetico: [
        { text: "Mantenedor", align: "center", value: "nomeMantenedor"},
        { text: "Quantidade de Unidades de Ensino Vinculadas", align: "center", value: "totalMantenedores" },
      ],
    }),
    computed: {
      isAnalitico() {
        return this.form.filters.analitico;
      },
    },
    methods: {
      getFilteredHeader(headers) {
        return headers.filter((header) => {
          if (header.onlyView) return !this.onReport;
          if (header.onlyReport) return this.onReport;
          return true;
        });
      },
      buscarRelatorios() {
        if (this.$validarFormulario()) {
          this.form.filters.cpf = this.$unMask(this.form.filters.cpf);
          const preparedRequest = JSON.parse(JSON.stringify(this.form.filters));
          preparedRequest.ativo = preparedRequest.situacao;
          delete preparedRequest.situacao;
          if (preparedRequest.dataFimTentativaLogin)
            preparedRequest.dataFimTentativaLogin = this.$formatarDateParaEnviar(
              preparedRequest.dataFimTentativaLogin,
              "23:59:59"
            );
          const buscarRelatorio = (params) => {
            return this.isAnalitico
              ? this.$obterRelatorioMantenedores(params)
              : this.$obterRelatorioMantenedoresSintetico(params);
          };

          this.showPageLoading();
          try {
            buscarRelatorio(this.$montarParamsFilters(preparedRequest)).then(
              ({ data }) => {
                this.form.mostrarAnalitico = this.isAnalitico;
                this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
                this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
                this.form.totalMantenedores = data.relatorio.totalMantenedores;
                this.form.lists.listRelatorios = data.relatorio.mantenedores;
              }
              ).then(() => this.hidePageLoading());
          } catch {
            this.hidePageLoading();
          }
        }
      },
      baixarRelatorioPdf() {
        if (!this.$validarFormulario()) {
          return;
        }

        this.form.filters.cpf = this.$unMask(this.form.filters.cpf);
        const preparedRequest = JSON.parse(JSON.stringify(this.form.filters));
        preparedRequest.ativo = preparedRequest.situacao;
        delete preparedRequest.situacao;
        if (preparedRequest.dataFimTentativaLogin)
          preparedRequest.dataFimTentativaLogin = this.$formatarDateParaEnviar(
            preparedRequest.dataFimTentativaLogin,
            "23:59:59"
          );

        const parametros = this.$montarParamsFilters(preparedRequest);

        const baixarPdf = (params) => {
          return this.isAnalitico
            ? this.$obterRelatorioPDFMantenedores(params)
            : this.$obterRelatorioPDFMantenedoresSintetico(params);
        };

        const relatorioNome = this.isAnalitico
            ? "SCAE.RelatórioMantenedorAnalítico.pdf"
            : "SCAE.RelatórioMantenedorSintético.pdf";

        this.showPageLoading();

        try {
          baixarPdf({ ...parametros, responseType: 'blob' })
            .then(({data}) => {
              const url = URL.createObjectURL(data);
              const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', relatorioNome);
                document.body.appendChild(link);
                link.click();
            })
            .then(() => this.hidePageLoading())
            .catch((err) => {
              this.notifyError(err.message);
              this.hidePageLoading();
            });
        } catch {
          this.showPageLoading();
        }

      },

      baixarRelatorioCsv() {
        if (!this.$validarFormulario()) {
          return;
        }

        this.form.filters.cpf = this.$unMask(this.form.filters.cpf);
        const preparedRequest = JSON.parse(JSON.stringify(this.form.filters));
        preparedRequest.ativo = preparedRequest.situacao;
        delete preparedRequest.situacao;
        if (preparedRequest.dataFimTentativaLogin)
          preparedRequest.dataFimTentativaLogin = this.$formatarDateParaEnviar(
            preparedRequest.dataFimTentativaLogin,
            "23:59:59"
          );

        const parametros = this.$montarParamsFilters(preparedRequest);

        const baixarRelatorio = (params) => {
          return this.isAnalitico
            ? this.$obterRelatorioCSVMantenedores(params)
            : this.$obterRelatorioCSVMantenedoresSintetico(params);
        };

        const relatorioNome = this.isAnalitico
                ? "SCAE.RelatórioMantenedorAnalítico"
                : "SCAE.RelatórioMantenedorSintético";

        this.showPageLoading();
        try {
          baixarRelatorio(parametros)
            .then(({ data }) => this.$baixarRelatorioCSV(data, relatorioNome))
            .then(() => this.hidePageLoading())
            .catch((err) => {
                this.notifyError(err.message);
                this.hidePageLoading();
              });
        } catch {
          this.hidePageLoading();
        }
      },
      resetForm() {
        this.form.lists.listRelatorios = [];

        this.$resetForm();
      },
      getMask(cpf) {
          if (cpf) {
            const numericCpf = cpf.replace(/\D/g, '');
            return numericCpf.length === 11 ? '###.###.###-##' : '##.###.###/####-##';
          }
          return '';
      },
      validacaoCpfCnpj(v) {
        const cleanedValue = v.replace(/\D/g, '');
        const digitCount = cleanedValue.length;

        // Verificar se o CPF/CNPJ tem a quantidade mínima de dígitos
        if (digitCount < 11) {
          return 'CPF/CNPJ inválido';
        }

        // Verificar se todos os dígitos são iguais, o que indica um número inválido
        if (/^(\d)\1+$/.test(cleanedValue)) {
          return 'CPF/CNPJ inválido';
        }

        // Verificar se é um CPF válido
        if (digitCount === 11 && !this.$validateCpf(cleanedValue)) {
          return 'CPF inválido';
        }

        // Verificar se é um CNPJ válido
        if (digitCount === 14 && !this.$validateCnpj(cleanedValue)) {
          return 'CNPJ inválido';
        }

        // Se passou por todas as verificações, é válido
        return true;
      },
    },
  };
</script>
