<template>
  <div class="page">
    <v-container fluid>
      <scae-breadcrumbs></scae-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="3">
            <v-autocomplete
              label="Etapas"
              :items="form.lists.etapa"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="form.filters.etapa"
              outlined
              dense
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              label="Modalidades"
              :items="form.lists.modalidade"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="form.filters.modalidade"
              outlined
              dense
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Oferta de Ensino"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <scae-select-ativo
            :cols="3"
            v-model="form.filters.ativo"
          ></scae-select-ativo>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <scae-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarOfertaEnsino()"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Incluir'"
              :icon="'mdi-plus'"
              @click="openDialogManterOfertaEnsino()"
            ></scae-tooltip>
          </v-col>
        </div>
      </v-form>
      <v-data-table
        class="mt-5"
        :headers="headers"
        :items="form.lists.listOfertaEnsino"
        :items-per-page="10"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.etapa.nome`]="{ item }">
          {{ item.etapa ? item.etapa.nome : ''}}
        </template>

        <template v-slot:[`item.modalidade.nome`]="{ item }">
          {{ item.modalidade ? item.modalidade.nome : '' }}
        </template>

        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>

        <template v-slot:[`item.ativo`]="{ item }">
          <scae-label-ativo :ativo="item.ativo"></scae-label-ativo>
        </template>

        <template v-slot:[`item.acoes`]="{ item }">
          <scae-tooltip
            :text="'Visualizar Oferta de Ensino'"
            :icon="'mdi-eye-outline'"
            :small="true"
            @click="openDialogVisualizarOfertaEnsino(item)"
          ></scae-tooltip>
          <scae-tooltip
            :text="'Editar Oferta de Ensino'"
            :icon="'mdi-pencil'"
            :small="true"
            @click="openDialogManterOfertaEnsino(item)"
          ></scae-tooltip>
          <scae-tooltip
            :text="`${item.ativo ? 'Inativar' : 'Ativar'} Oferta de Ensino`"
            :icon="item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'"
            :color="item.ativo ? 'blue' : 'red'"
            :small="true"
            @click="inativarAtivarOfertasEnsino(item)"
          ></scae-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CadastrarEditarOfertaEnsinoDialog
      :visible="dialogManterOfertaDeEnsino.visible"
      :content="dialogManterOfertaDeEnsino.content"
      :edit="dialogManterOfertaDeEnsino.edit"
      @onClose="dialogManterOfertaDeEnsino.close()"
      @onSave="buscarOfertaEnsino(),recarregarOfertasDeEnsinoAutocomplete()"
    ></CadastrarEditarOfertaEnsinoDialog>
    <VisualizarOfertaEnsinoDialog
      :visible="dialogVisualizarOfertaDeEnsino.visible"
      :content="dialogVisualizarOfertaDeEnsino.content"
      @onClose="dialogVisualizarOfertaDeEnsino.close()"
    ></VisualizarOfertaEnsinoDialog>
  </div>
</template>

<script>
import { Dialog, OfertaDeEnsino } from "~/commons/classes";
import CadastrarEditarOfertaEnsinoDialog from "./dialogs/CadastrarEditarOfertaEnsino.vue";
import VisualizarOfertaEnsinoDialog from "./dialogs/VisualizarOfertaEnsino.vue";

export default {
  name: "ManterOfertaEnsinoView",
  components: {
      CadastrarEditarOfertaEnsinoDialog,
      VisualizarOfertaEnsinoDialog,
  },
  data: () => ({
    dialogManterOfertaDeEnsino: Dialog.Build(),
    dialogVisualizarOfertaDeEnsino: Dialog.Build(),
    form: {
      filters: {
        etapa: null,
        modalidade: null,
        nome: null,
        ativo: null,
      },
      lists: {
          listOfertaEnsino: [],
          modalidade: [],
          etapa: [],
          ofertaDeEnsino: []
      }
    },
    headers: [
      { text: "Etapa", align: "center", value: "etapa.nome" },
      { text: "Modalidade", align: "center", value: "modalidade.nome" },
      { text: "Oferta de Ensino", align: "center", value: "nome" },
      { text: "Ativo", align: "center", value: "ativo" },
      { text: "Ações", align: "center", value: "acoes", sortable: false },
    ]
  }),
  methods: {
    recarregarOfertasDeEnsinoAutocomplete() {
      this.form.lists.ofertaDeEnsino = null;
      this.$buscarOfertasEnsinos().then(({ data }) => {
        this.form.lists.ofertaDeEnsino = data.ofertasDeEnsino.map((oferta) => ({
          id: oferta.id,
          nome: oferta.nome,
        }));
      });
    },
    openDialogManterOfertaEnsino(ofertaEnsino) {
      ofertaEnsino
        ? this.$visualizarOfertasEnsinos(ofertaEnsino.id).then(({ data }) => {
            this.dialogManterOfertaDeEnsino.open(new OfertaDeEnsino(data.itemOfertaEnsino), true);
          })
        : this.dialogManterOfertaDeEnsino.open(OfertaDeEnsino.Build());
    },
    openDialogVisualizarOfertaEnsino(ofertaEnsino) {
      this.$visualizarOfertasEnsinos(ofertaEnsino.id).then(
        ({ data }) => (this.dialogVisualizarOfertaDeEnsino.open(new OfertaDeEnsino(data.itemOfertaEnsino)))
      );
    },
    buscarOfertaEnsino() {
      const params = {
        idEtapa: this.form.filters.etapa ? this.form.filters.etapa.id : null,
        idModalidade: this.form.filters.modalidade ? this.form.filters.modalidade.id : null,
        nome: this.form.filters.nome ? this.form.filters.nome : null,
        ativo: this.form.filters.ativo,
      };

      this.$buscarOfertasEnsinos({ params }).then(({ data }) => {
        this.form.lists.listOfertaEnsino = data.ofertasDeEnsino;
      });
    },
    inativarAtivarOfertasEnsino(ofertaEnsino) {
      this.$swal({
        text: `Deseja confirmar a ${
          ofertaEnsino.ativo ? "inativação" : "ativação"
        } da Oferta de Ensino ${ofertaEnsino.nome}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
      }).then(() => {
        this.$inativarAtivarOfertasEnsino(
          { ofertaDeEnsinoId: ofertaEnsino.id, ativo: !ofertaEnsino.ativo },
          ofertaEnsino.id
        ).then(({ data }) => {
          this.notifySuccess(data.mensagem);
          this.buscarOfertaEnsino();
        });
      });
    },
    resetForm() {
      this.$resetForm();
      this.form.lists.listOfertaEnsino = [];
    },
  },
  mounted() {
    this.$obterEtapas().then(({ data }) => {
      this.form.lists.etapa = data.lista;
    });
    this.$obterModalidades().then(({ data }) => {
      this.form.lists.modalidade = data.modalidades;
    });
  }
};
</script>