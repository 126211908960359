<template>
    <div class="page">
      <v-container fluid>
        <scae-breadcrumbs></scae-breadcrumbs>
        <v-form ref="form">
          <v-row class="d-flex justify-center mt-3">
            <v-col cols="2">
              <v-text-field
                label="Cadastro Escolar"
                v-model="form.filters.codEscolar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Unidade de Ensino"
                v-model="form.filters.nomeUnidadeEnsino"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                label="Sit. Funcionamento"
                :items="form.lists.listSitFuncionamento"
                :item-text="'nome'"
                :item-value="'id'"
                v-model="form.filters.sitFuncionamentoId"
                outlined
                dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
              <v-autocomplete
                label="Dependência Adm."
                :items="form.lists.listDependenciaAdm"
                :item-text="'nome'"
                :item-value="'id'"
                v-model="form.filters.dependenciaAdmId"
                outlined
                dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
              <v-autocomplete
                label="Município"
                :items="form.lists.listMunicipio"
                :item-text="'nome'"
                :item-value="'id'"
                v-model="form.filters.municipioId"
                outlined
                dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
              <v-autocomplete
                label="Unid. Regional"
                :items="form.lists.listUnidadeRegional"
                :item-text="'nome'"
                :item-value="'id'"
                v-model="form.filters.unidRegionalId"
                outlined
                dense
                ></v-autocomplete>
              </v-col>
          </v-row>
          <div class="text-center">
            <v-col cols="12">
              <scae-tooltip
                :text="'Buscar'"
                :icon="'mdi-magnify'"
                @click="buscarUnidadeDeEnsino()"
              ></scae-tooltip>
              <scae-tooltip
                :text="'Limpar'"
                :icon="'mdi-eraser'"
                @click="resetForm()"
              ></scae-tooltip>
              <scae-tooltip
                :text="'Incluir'"
                :icon="'mdi-plus'"
                @click="openDialogManterUnidadeDeEnsino()"
              ></scae-tooltip>
            </v-col>
          </div>
        </v-form>
        <v-data-table
          class="mt-5"
          :headers="headers"
          :items="form.lists.listUnidadeDeEnsino"
          :items-per-page="10"
          :footer-props="$footerProps"
          >
          <!-- show-expand -->
          <template v-slot:[`item.codigo`]="{ item }">
            {{ item.codigo }}
          </template>
          <template v-slot:[`item.nomeUnidadeEnsino`]="{ item }">
            {{ item.nomeUnidadeEnsino }}
          </template>
          <template v-slot:[`item.cnpj`]="{ item }">
            {{ item.cnpj }}
          </template>
          <template v-slot:[`item.situacaoFuncionamento`]="{ item }">
            {{ item.situacaoFuncionamento.nome }}
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <scae-label-ativo :ativo="item.ativo"></scae-label-ativo>
          </template>
          <template v-slot:[`item.acoes`]="{ item }">
            <scae-tooltip
              :text="'Visualizar Unidade de Ensino'"
              :icon="'mdi-eye-outline'"
              :small="true"
              @click="openDialogVisualizarUnidadeDeEnsino(item)"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Editar Unidade de Ensino'"
              :icon="'mdi-pencil'"
              :small="true"
              :disabled="!item.ativo"
              @click="openDialogManterUnidadeDeEnsino(item)"
            ></scae-tooltip>
            <scae-tooltip
              :text="`${item.ativo ? 'Inativar' : 'Ativar'} Unidade de Ensino`"
              :icon="item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'"
              :color="item.ativo ? 'blue' : 'red'"
              :small="true"
              @click="inativarAtivarUnidadeDeEnsino(item)"
            ></scae-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td class="resposta" :colspan="headers.length">
              {{ item.resposta }}
            </td>
          </template>
        </v-data-table>
      </v-container>
      <CadastrarEditarUnidadeEnsinoDialog
        :visible="dialogManterUnidadeDeEnsino.visible"
        :content="dialogManterUnidadeDeEnsino.content"
        :edit="dialogManterUnidadeDeEnsino.edit"
        @onClose="dialogManterUnidadeDeEnsino.close()"
        @onSave="buscarUnidadeDeEnsino()"
      ></CadastrarEditarUnidadeEnsinoDialog>
      <VisualizarUnidadeEnsinoDialog
        :visible="dialogVisualizarUnidadeDeEnsino.visible"
        :content="dialogVisualizarUnidadeDeEnsino.content"
        @onClose="dialogVisualizarUnidadeDeEnsino.close()"
      ></VisualizarUnidadeEnsinoDialog>
    </div>
  </template>
  
  <script>
  import pageLoadingMixin from "mixins/page-loading";
  import { Dialog, UnidadeDeEnsino } from "~/commons/classes";
  import CadastrarEditarUnidadeEnsinoDialog from "./dialogs/CadastrarEditarUnidadeEnsinoDialog.vue";
  import VisualizarUnidadeEnsinoDialog from "./dialogs/VisualizarUnidadeEnsinoDialog.vue";
  
  export default {
    name: "ManterUnidadeEnsinoView",
    components: {
      CadastrarEditarUnidadeEnsinoDialog,
      VisualizarUnidadeEnsinoDialog,
    },
    mixins: [pageLoadingMixin],
    data: () => ({
      dialogManterUnidadeDeEnsino: Dialog.Build(),
      dialogVisualizarUnidadeDeEnsino: Dialog.Build(),
      form: {
        filters: {
          id: null,
          codEscolar: null,
          nomeUnidadeEnsino: null,
          sitFuncionamentoId: null,
          dependenciaAdmId: null,
          municipioId: null,
          unidRegionalId: null,
        },
        lists: {
          listDependenciaAdm: [],
          listSitFuncionamento: [],
          listIdentificacao: [],
          listLocalizacao: [],
          listPortaria: [],
          listMunicipio: [],
          listUnidadeRegional: [],
          listUnidadeDeEnsino: []
        },
      },
      headers: [
        { text: "Cadastro Escolar", align: "center", value: "codigo" },
        { text: "Unidade de Ensino", align: "center", value: "nomeUnidadeEnsino" },
        { text: "CNPJ", align: "center", value: "cnpj" },
        { text: "Situação do Funcionamento", align: "center", value: "situacaoFuncionamento" },
        { text: "Ações", align: "center", value: "acoes", sortable: false },
      ],
    }),
    methods: {
      openDialogManterUnidadeDeEnsino(unidadeDeEnsino) {
        this.showPageLoading();
        try {
          unidadeDeEnsino
            ? this.$visualizarDadosUnidadeDeEnsino(unidadeDeEnsino.id).then(({ data }) => {
                this.dialogManterUnidadeDeEnsino.open(new UnidadeDeEnsino(data.unidadeEnsino).formatInverso(), true);
              })
            : this.dialogManterUnidadeDeEnsino.open(UnidadeDeEnsino.Build());
        } finally {
          this.hidePageLoading();
        }
      },
      openDialogVisualizarUnidadeDeEnsino(unidadeDeEnsino) {
        this.showPageLoading();
        try {
          this.$visualizarDadosUnidadeDeEnsino(unidadeDeEnsino.id).then(
            ({ data }) => {
              this.dialogVisualizarUnidadeDeEnsino.open(new UnidadeDeEnsino(data.unidadeEnsino));
            });
        } finally {
          this.hidePageLoading();
        }
      },
      buscarUnidadeDeEnsino() {
        this.showPageLoading();

        this.$buscarDadosUnidadeDeEnsino({ params: this.form.filters })
          .then(({ data }) => {
            try {
              this.form.lists.listUnidadeDeEnsino = data.lista;
            } finally {
              this.hidePageLoading();
            }
          })
          .catch(() => { 
            this.hidePageLoading();
          });
      },
      inativarAtivarUnidadeDeEnsino(unidadeDeEnsino) {
        this.$swal({
          text: `Deseja confirmar a ${
            unidadeDeEnsino.ativo ? "inativação" : "ativação"
          } da Unidade de Ensino ${unidadeDeEnsino.nomeUnidadeEnsino}?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          this.$inativarAtivarPerguntaUnidadeDeEnsino(
            { id: unidadeDeEnsino.id, ativo: !unidadeDeEnsino.ativo },
            unidadeDeEnsino.id
          ).then(({ data }) => {
            this.notifySuccess(data.mensagem);
            this.buscarUnidadeDeEnsino();
          });
        });
      },
      resetForm() {
        this.$resetForm();
        this.form.lists.listUnidadeDeEnsino = [];
      },
    },
    mounted() {
      this.$obterDependenciaAdm().then(({ data }) => {
        this.form.lists.listDependenciaAdm = data.lista;
      });
      this.$obterSitFuncionamento().then(({ data }) => {
        this.form.lists.listSitFuncionamento = data.lista;
      });
      this.$obterMunicipio().then(({ data }) => {
        this.form.lists.listMunicipio = data.lista;
      });
      this.$obterUnidadeRegional().then(({ data }) => {
        this.form.lists.listUnidadeRegional = data.lista;
      });
    }
  };
  </script>
  