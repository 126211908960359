<template>
    <div class="text-center">
      <v-dialog persistent v-model="visible" width="800">
        <v-card class="not-margin">
          <v-toolbar dark color="primary">
            <v-icon>mdi-eye</v-icon>
            <div class="ml-2">Visualizar FAQ</div>
          </v-toolbar>
          <div class="container dialog p-7 mt-7">
            <v-row class="d-flex justify-center">
              <v-col cols="8">
                <v-text-field
                  v-model="content.pergunta"
                  label="Pergunta"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="content.posicao"
                  label="Posição"
                  type="number"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-4">
              <v-col cols="12">
                <v-textarea
                  v-model="content.resposta"
                  label="Resposta"
                  outlined
                  readonly
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row justify="center" class="mt-4">
              <v-col cols="12">
                <v-sheet elevation="5" class="pa-6 palavras-chave-sheet">
                  <div class="title-palavras-chave">Palavras-Chave</div>
                  <v-chip-group active-class="primary--text" column>
                    <v-chip v-for="tag in content.palavrasChave" :key="tag">
                      <v-icon small class="mdi-key-icon">mdi-key</v-icon>{{ tag }}
                    </v-chip>
                  </v-chip-group>
                </v-sheet>
              </v-col>
            </v-row>
          </div>
          <div class="btn-group text-center pb-4">
            <v-btn color="primary" @click="$emit('onClose')">
              <v-icon>mdi-close</v-icon>
              Fechar
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { DialogProps, FAQ } from '~/commons/classes';
  
  export default {
    props: new DialogProps(FAQ),
  };
  </script>
<style scoped>
.v-icon.notranslate.mdi.mdi-key.theme--light {
  padding: 0.4em;
}

.title-palavras-chave {
  display: flex;
  padding: 1.5em;
  justify-content: center;
  border-bottom: 1px solid #d7d7d7;
}

.palavras-chave-sheet {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.mdi-key-icon {
  margin-right: 4px;
}
</style>
  