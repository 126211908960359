<template>
    <div class="page">
      <v-container fluid>
        <scae-breadcrumbs></scae-breadcrumbs>
        <v-form ref="form">
          <v-row class="d-flex justify-center mt-3">
            <v-col cols="3">
              <v-text-field
                label="Finalidade"
                v-model="form.filters.nome"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <scae-select-ativo
              :cols="3"
              v-model="form.filters.ativo"
            ></scae-select-ativo>
          </v-row>
          <div class="text-center">
            <v-col cols="12">
              <scae-tooltip
                :text="'Buscar'"
                :icon="'mdi-magnify'"
                @click="buscarFinalidades()"
              ></scae-tooltip>
              <scae-tooltip
                :text="'Limpar'"
                :icon="'mdi-eraser'"
                @click="resetForm()"
              ></scae-tooltip>
              <scae-tooltip
                :text="'Incluir'"
                :icon="'mdi-plus'"
                @click="openDialogManterFinalidade()"
              ></scae-tooltip>
            </v-col>
          </div>
        </v-form>
        <v-data-table
          class="mt-5"
          :headers="headers"
          :items="form.lists.listFinalidades"
          :items-per-page="10"
          :footer-props="$footerProps"
        >
          <template v-slot:[`item.nome`]="{ item }">
            {{ item.nome }}
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <scae-label-ativo :ativo="item.ativo"></scae-label-ativo>
          </template>
          <template v-slot:[`item.acoes`]="{ item }">
            <scae-tooltip
              :text="'Visualizar Finalidade'"
              :icon="'mdi-eye-outline'"
              :small="true"
              @click="openDialogVisualizarFinalidade(item)"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Editar Finalidade'"
              :icon="'mdi-pencil'"
              :small="true"
              @click="openDialogManterFinalidade(item)"
            ></scae-tooltip>
            <scae-tooltip
              :text="`${item.ativo ? 'Inativar' : 'Ativar'} Finalidade`"
              :icon="item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'"
              :color="item.ativo ? 'blue' : 'red'"
              :small="true"
              @click="inativarAtivarFinalidade(item)"
            ></scae-tooltip>
          </template>
        </v-data-table>
      </v-container>
      <CadastrarEditarFinalidadeDialog
        :visible="dialogManterFinalidade.visible"
        :content="dialogManterFinalidade.content"
        :edit="dialogManterFinalidade.edit"
        @onClose="dialogManterFinalidade.close()"
        @onSave="buscarFinalidades()"
      ></CadastrarEditarFinalidadeDialog>
      <VisualizarFinalidadeDialog
        :visible="dialogVisualizarFinalidade.visible"
        :content="dialogVisualizarFinalidade.content"
        @onClose="dialogVisualizarFinalidade.close()"
      ></VisualizarFinalidadeDialog>
    </div>
  </template>
  
  <script>
  import { Dialog, Finalidade } from "~/commons/classes";
  import CadastrarEditarFinalidadeDialog from "../../administracao/gerenciar-finalidade/dialogs/CadastrarEditarFinalidade.vue";
  import VisualizarFinalidadeDialog from "../../administracao/gerenciar-finalidade/dialogs/VisualizarFinalidadeDialog.vue";
  
  export default {
    name: "ManterFinalidadeView",
    components: {
      CadastrarEditarFinalidadeDialog,
      VisualizarFinalidadeDialog,
    },
    data: () => ({
      dialogManterFinalidade: Dialog.Build(),
      dialogVisualizarFinalidade: Dialog.Build(),
      form: {
        filters: {
          finalidade: null,
          ativo: null,
        },
        lists: {
            listFinalidades: []
        }
      },
      headers: [
        { text: "Finalidade", align: "center", value: "nome" },
        { text: "Ativo", align: "center", value: "ativo" },
        { text: "Ações", align: "center", value: "acoes", sortable: false },
      ]
    }),
    methods: {
      openDialogManterFinalidade(finalidade) {
        finalidade
          ? this.$visualizarFinalidade(finalidade.id).then(({ data }) => {
              this.dialogManterFinalidade.open(new Finalidade(data.finalidade), true);
            })
          : this.dialogManterFinalidade.open(Finalidade.Build());
      },
      openDialogVisualizarFinalidade(finalidade) {
        this.$visualizarFinalidade(finalidade.id).then(
          ({ data }) => (this.dialogVisualizarFinalidade.open(new Finalidade(data.finalidade)))
        );
      },
      buscarFinalidades() {
        this.$obterFinalidades({ params: this.form.filters }).then(
          ({ data }) => (this.form.lists.listFinalidades = data.finalidades)
        );
      },
      inativarAtivarFinalidade(finalidade) {
        this.$swal({
          text: `Deseja confirmar a ${
            finalidade.ativo ? "inativação" : "ativação"
          } da Finalidade ${finalidade.nome}?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          this.$inativarAtivarFinalidade(
            { finalidadeId: finalidade.id, ativo: !finalidade.ativo },
            finalidade.id
          ).then(({ data }) => {
            this.notifySuccess(data.mensagem);
            this.buscarFinalidades();
          });
        });
      },
      resetForm() {
        this.$resetForm();
        this.form.lists.listFinalidades = [];
        this.form.filters.ativo = null;
      },
    },
  };
</script>
  