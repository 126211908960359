<template>
    <div class="text-center">
      <v-dialog persistent v-model="visible" width="800">
        <v-card class="not-margin pt-0 pr-0 pl-0">
          <v-toolbar dark color="primary">
            <div class="text-center">
              <v-icon>mdi-pencil</v-icon>
              {{ edit ? "Editar" : "Cadastrar" }} Finalidade
            </div>
          </v-toolbar>
          <div class="container dialog">
            <v-form ref="form" v-if="visible">
              <v-row class="d-flex justify-center mt-7">
                <v-col cols="12">
                  <v-text-field
                    v-model="content.nome"
                    label="Finalidade"
                    counter="252"
                    outlined
                    dense
                    :rules="[(v) => $required(v), (v) => $max(v, 252)]"
                    @keydown.enter.prevent="onEnterPressed"
                    maxlength="252"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <scae-buttons
            @click="salvar()"
            @cancel="$emit('onClose')"
            :actionText="edit ? 'Editar' : 'Salvar'"
          ></scae-buttons>
        </v-card>
      </v-dialog>
    </div>
  </template>  
  <script>
  import { DialogProps, Finalidade } from '~/commons/classes';
  export default {
    props: new DialogProps(Finalidade),
    methods: {
      salvar() {
        const save = () => {
          return this.$swal({
            text: `Confirma ${
              this.edit ? "a edição" : "o cadastro"
            } da Finalidade?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Cancelar`,
          }).then(() => {
            const payload = {
            ...this.content,
              finalidadeId: this.content.id,
            };
            return this.edit
              ? this.$atualizarFinalidade(payload, this.content.id)
              : this.$cadastrarFinalidade(new Finalidade(payload));
          });
        };
        if (this.$validarFormulario()) {
            save().then(({ data }) => {
              this.notifySuccess(data.mensagem);
              this.$emit("onClose");
              this.$emit("onSave");
            })
            .catch((error) => {
                this.notifyError(error.response.data.mensagem);
            })
        }
      },
      onEnterPressed() {
      // Evita a ação padrão do Enter
      },
    },
  };
  </script>
  