import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c(VContainer,{attrs:{"fluid":""}},[_c('scae-breadcrumbs'),_c(VForm,{ref:"form"},[_c(VRow,{staticClass:"d-flex justify-center mt-3"},[_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"label":"Nome do grupo","outlined":"","dense":""},model:{value:(_vm.form.filters.nome),callback:function ($$v) {_vm.$set(_vm.form.filters, "nome", $$v)},expression:"form.filters.nome"}})],1),_c('scae-select-ativo',{attrs:{"cols":3},model:{value:(_vm.form.filters.ativo),callback:function ($$v) {_vm.$set(_vm.form.filters, "ativo", $$v)},expression:"form.filters.ativo"}})],1),_c('div',{staticClass:"text-center"},[_c(VCol,{attrs:{"cols":"12"}},[_c('scae-tooltip',{attrs:{"text":'Buscar',"icon":'mdi-magnify'},on:{"click":function($event){return _vm.buscarGrupos()}}}),_c('scae-tooltip',{attrs:{"text":'Limpar',"icon":'mdi-eraser'},on:{"click":function($event){return _vm.resetForm()}}}),_c('scae-tooltip',{attrs:{"text":'Incluir',"icon":'mdi-plus'},on:{"click":function($event){return _vm.openDialogManterGrupo()}}})],1)],1)],1),_c(VDataTable,{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.form.lists.listGrupos,"items-per-page":10,"footer-props":_vm.$footerProps,"show-expand":""},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome)+" ")]}},{key:"item.nomeDeExibicao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomeDeExibicao)+" ")]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('scae-label-ativo',{attrs:{"ativo":item.ativo}})]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('scae-tooltip',{attrs:{"text":'Visualizar Grupo',"icon":'mdi-eye-outline',"small":true},on:{"click":function($event){return _vm.openDialogVisualizarGrupo(item)}}}),_c('scae-tooltip',{attrs:{"text":'Editar Grupo',"icon":'mdi-pencil',"small":true},on:{"click":function($event){return _vm.openDialogManterGrupo(item)}}}),_c('scae-tooltip',{attrs:{"text":((item.ativo ? 'Inativar' : 'Ativar') + " Grupo"),"icon":item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch',"color":item.ativo ? 'blue' : 'red',"small":true},on:{"click":function($event){return _vm.inativarAtivarGrupo(item)}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"permissoes",attrs:{"colspan":headers.length}},[_c(VChipGroup,{attrs:{"active-class":"primary--text","column":""}},_vm._l((item.permissoes),function(permissao,i){return _c(VChip,{key:i,staticClass:"chip-permissao"},[_c(VIcon,{staticClass:"permissao",attrs:{"small":""}},[_vm._v("mdi-checkbox-marked-circle-outline")]),_vm._v(" "+_vm._s(permissao.nome)+" ")],1)}),1)],1)]}}],null,true)})],1),_c('CadastrarEditarGrupoDialog',{attrs:{"visible":_vm.dialogManterGrupo.visible,"content":_vm.dialogManterGrupo.content,"edit":_vm.dialogManterGrupo.edit},on:{"onClose":function($event){return _vm.dialogManterGrupo.close()},"onSave":function($event){return _vm.buscarGrupos()}}}),_c('VisualizarGrupoDialog',{attrs:{"visible":_vm.dialogVisualizarGrupo.visible,"content":_vm.dialogVisualizarGrupo.content},on:{"onClose":function($event){return _vm.dialogVisualizarGrupo.close()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }