<template>
    <div class="text-center">
      <v-dialog persistent v-model="visible" width="800">
        <v-card class="not-margin pt-0 pr-0 pl-0">
          <v-toolbar dark color="primary">
            <div class="text-center">
              <v-icon>mdi-pencil</v-icon>
              {{ edit ? "Editar" : "Cadastrar" }} Grupo
            </div>
          </v-toolbar>
          <div class="container dialog">
            <v-form ref="form" v-if="visible">
              <v-row class="d-flex justify-center mt-7">
                <v-col cols="12">
                  <v-text-field
                    v-model="content.nome"
                    label="Nome"
                    counter="252"
                    outlined
                    dense
                    :rules="[(v) => $required(v), (v) => $max(v, 252)]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center mt-7">
                <v-col cols="12">
                  <v-text-field
                    v-model="content.nomeDeExibicao"
                    label="Nome de exibição"
                    counter="252"
                    outlined
                    dense
                    :rules="[(v) => $required(v), (v) => $max(v, 252)]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center mt-7">
                <v-col cols="12">
                  <v-select
                    v-model="content.permissoes"
                    :items="form.lists.permissoes"
                    :item-text="'nome'"
                    :item-value="'id'"
                    :rules="[(p) => p.some(v => $required(v)) || 'Informe uma ou mais permissões associadas']"
                    label="Permissões *"
                    multiple
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <scae-buttons
            @click="salvar()"
            @cancel="$emit('onClose')"
            :actionText="edit ? 'Editar' : 'Salvar'"
          ></scae-buttons>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { DialogProps, Grupo } from '~/commons/classes';
  
  export default {
    props: new DialogProps(Grupo),
    data: () => ({
      form: {
        lists: {
          permissoes: [],
        },
      }
    }),
    methods: {
      salvar() {
        const save = () => {
          return this.$swal({
            text: `Confirma ${
              this.edit ? "a edição" : "o cadastro"
            } do Grupo?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Cancelar`,
          }).then(() => {
            return this.edit
              ? this.$editarGrupo(this.content)
              : this.$cadastrarGrupo(this.content);
          });
        };
        if (this.$validarFormulario()) {
          if (this.content.permissoes.length) {
            save()
              .then(({ data }) => {
                this.notifySuccess(`${data.mensagem}<br/><i>Para consolidar as alterações, é necessário sair e entrar novamente no sistema!</i>`);
                this.$emit("onClose");
                this.$emit("onSave");
              })
              .catch(error => {
                this.notifyError(error.response.data.mensagem);
              });
          } else this.$notifyWarning("Informe uma ou mais permissões associadas");
        }
      },
    },
    mounted() {
      this.$buscarTodasPermissoes().then(({ data }) => {
        this.form.lists.permissoes = data.permissoes;
      });
    }
  };
  </script>
  