<template>
    <div class="text-center">
      <v-dialog persistent v-model="visible" width="800">
        <v-card class="not-margin pt-0 pr-0 pl-0">
          <v-toolbar dark color="primary">
            <div class="text-center">
              <v-icon>mdi-pencil</v-icon>
              {{ edit ? "Editar" : "Cadastrar" }} Oferta de Ensino
            </div>
          </v-toolbar>
          <div class="container dialog">
            <v-form ref="form" v-if="visible">
                <v-row class="d-flex justify-center mt-7">
                    <v-col cols="6">
                    <v-autocomplete
                      label="Etapas"
                      :items="form.lists.etapa"
                      :item-text="'nome'"
                      :item-value="'id'"
                      v-model="content.etapa[0]"
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                      label="Modalidades"
                      :items="form.lists.modalidade"
                      :item-text="'nome'"
                      :item-value="'id'"
                      v-model="content.modalidade[0]"
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                    <v-col cols="12">
                        <v-text-field
                        v-model="content.nome"
                        label="Ano/Série/Módulo/Fase*"
                        counter="252"
                        outlined
                        dense
                        :rules="[(v) => $required(v), (v) => $max(v, 252)]"
                    ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
          </div>
          <scae-buttons
            @click="salvar()"
            @cancel="$emit('onClose')"
            :actionText="edit ? 'Editar' : 'Salvar'"
          ></scae-buttons>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { DialogProps, OfertaDeEnsino } from '~/commons/classes';
  
  export default {
    props: new DialogProps(OfertaDeEnsino),
    data: () => ({
      form: {
        lists: {
            modalidade: [],
            etapa: [],
        },
      }
    }),
    methods: {
      salvar() {
        if (this.$validarFormulario()) {
          if (!this.content.etapa[0]?.id && !this.content.modalidade[0]?.id) {
            this.notifyError("Preencha pelo menos um dos campos Etapa ou Modalidade.");
            return;
          }
          const save = () => {
            return this.$swal({
              text: `Confirma ${
                this.edit ? "a edição" : "o cadastro"
              } da Oferta de Ensino?`,
              type: "warning",
              showCancelButton: true,
              confirmButtonText: `Confirmar`,
              cancelButtonText: `Cancelar`,
            }).then(() => {
              const payload = {
                id: this.edit ? this.content.id : null,
                etapa: this.content.etapa,
                modalidade:this.content.modalidade,
                nome: this.content.nome,
                ativo: true,
              };
              return this.edit
                ? this.$editarOfertasEnsino(payload)
                : this.$cadastrarOfertasEnsino(payload);
            });
          };
          if (this.$validarFormulario()) {
            save().then(({ data }) => {
              this.notifySuccess(data.mensagem);
              this.$emit("onClose");
              this.$emit("onSave");
            })
            .catch((error) => {
              this.notifyError(error.response.data.mensagem);
            })
          }
        }
      },
    },
    mounted() {
      this.$obterEtapas().then(({ data }) => {
        this.form.lists.etapa = data.lista;
      });
      this.$obterModalidades().then(({ data }) => {
        this.form.lists.modalidade = data.modalidades;
      });
    }

};
</script>
  