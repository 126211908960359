<template>
  <div class="page">
    <v-container fluid>

      <scae-breadcrumbs></scae-breadcrumbs>

      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="2" v-show="form.filtros.analitico">
            <v-autocomplete
              label="Unid. Regional"
              :items="form.lists.unidadesRegionais"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="form.filtros.unidadeRegionalId"
              outlined
              dense>
            </v-autocomplete>
          </v-col>
          <v-col cols="2" v-show="form.filtros.analitico">
            <v-autocomplete
              label="Situação de Funcionamento"
              :items="form.lists.situacoesFuncionamento"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="form.filtros.situacaoFuncionamentoId"
              outlined
              dense>
            </v-autocomplete>
          </v-col>
          <v-col cols="2" v-show="form.filtros.analitico">
              <v-text-field
                label="Unidade de Ensino"
                v-model="form.filtros.nome"
                outlined
                dense>
              </v-text-field>
          </v-col>
          <v-col cols="2" v-show="form.filtros.analitico">
            <v-autocomplete
              label="Dependência Adm."
              :items="form.lists.dependenciasAdministrativas"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="form.filtros.dependenciaAdministrativaId"
              outlined
              dense>
            </v-autocomplete>
          </v-col>
          <v-col cols="2" v-show="form.filtros.analitico">
            <v-autocomplete
              label="Município"
              :items="form.lists.municipios"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="form.filtros.municipioId"
              outlined
              dense>
            </v-autocomplete>
          </v-col>
          <scae-switch-button
            v-model="form.filtros.analitico"
            :cols="2"
            label="Tipo de Relatório"
            labelChecked="Analítico"
            labelUnchecked="Sintético"
            @click="limparForm()"
          ></scae-switch-button>
        </v-row>

        <div class="text-center">
          <v-col cols="12">
            <scae-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="obterUnidadesEnsino()"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="limparForm()"
            ></scae-tooltip>
          </v-col>
        </div>
      </v-form>

      <v-data-table
        v-if="form.filtros.analitico"
        class="mt-5 printable"
        :headers="obterHeaders(headersAnalitico)"
        :items="form.lists.relatorio"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps"
        item-key="id">
        <template v-slot:[`item.unidadeRegional`]="{ item }">
          {{ item.unidadeRegional }}
        </template>
        <template v-slot:[`item.situacaoFuncionamento`]="{ item }">
          {{ item.situacaoFuncionamento }}
        </template>
        <template v-slot:[`item.cadastroEscolar`]="{ item }">
          {{ item.cadastroEscolar }}
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.dependenciaAdministrativa`]="{ item }">
          {{ item.dependenciaAdministrativa }}
        </template>
        <template v-slot:[`item.municipio`]="{ item }">
          {{ item.municipio }}
        </template>
      </v-data-table>

      <v-data-table
        v-else
        class="mt-5 printable"
        :headers="obterHeaders(headersSintetico)"
        :items="form.lists.relatorio"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps">
        <template v-slot:[`item.situacaoFuncionamento`]="{ item }">
          {{ item.situacaoFuncionamento.nome }}
        </template>
        <template v-slot:[`item.quantidade`]="{ item }">
          {{ item.quantidade }}
        </template>
      </v-data-table>

      <scae-buttons
        v-if="form.lists.relatorio.length"
        :actionText="'Baixar PDF'"
        :actionIcon="'mdi-printer'"
        :cancelText="'Baixar CSV'"
        :cancelIcon="'mdi-file'"
        @click="baixarRelatorioPdf()"
        @cancel="baixarRelatorioCvs()">
      </scae-buttons>
    </v-container>
  </div>
</template>

<script>
  import pageLoadingMixin from "mixins/page-loading";

  export default {
    name: "ListarRelatorioUnidadesEnsinoView",
    mixins: [pageLoadingMixin],

    data: () => ({
      onReport: false,
      componentKey: 0,
      form: {
        filtros: {
          unidadeRegional: null,
          situacaoFuncionamento: null,
          nome: '',
          dependenciaAdministrativa: null,
          municipio: null,
          analitico: true
        },
        lists: {
          relatorio: [],
          unidadesRegionais: [],
          situacoesFuncionamento: [],
          dependenciasAdministrativas: []
        },
        itemsPerPage: 10,
        geradorRelatorio: null,
        horaDaGeracao: null,
        totalUsuarios: 0,
        mostrarAnalitico: true,
      },
      headersAnalitico: [
        { text: "Unid. Regional", align: "center", value: "unidadeRegional"},
        { text: "Sit. Funcionamento", align: "center", value: "situacaoFuncionamento"},
        { text: "Cadastro Escolar", align: "center", value: "cadastroEscolar"},
        { text: "Unidade de Ensino", align: "center", value: "nome"},
        { text: "Dependência Adm.", align: "center", value: "dependenciaAdministrativa"},
        { text: "Município", align: "center", value: "municipio"}
      ],
      headersSintetico: [
        { text: "Situação de Funcionamento", align: "center", value: "situacaoFuncionamento" },
        { text: "Quantidade", align: "center", value: "quantidade" }
      ],
    }),

    computed: {
      isAnalitico() {
        return this.form.filtros.analitico;
      },
    },

    methods: {
      obterHeaders(headers) {
        return headers.filter((header) => {
          if (header.onlyView) return !this.onReport;
          if (header.onlyReport) return this.onReport;
          return true;
        });
      },

      limparForm() {
        this.$resetForm();
        this.form.lists.relatorio = [];
      },

      obterUnidadesEnsino() {
        if (!this.$validarFormulario()) {
          return;
        }

        const parametros = this.$montarParamsFilters(this.form.filtros);
        const servico = this.form.filtros.analitico
                      ? this.$obterRelatorioUnidadesEnsinoAnalitico
                      : this.$obterRelatorioUnidadesEnsinoSintetico;

        this.showPageLoading();
        try {
          servico(parametros)
              .then(({ data }) => {
                  if (this.form.filtros.analitico) {
                    this.form.lists.relatorio = data.unidadesEnsino;
                  } else {
                    this.form.lists.relatorio = data.unidadesEnsinoSinteticoDto;
                  }
              })
              .then(() => this.hidePageLoading())
              .catch((err) => {
                this.notifyError(err.message);
                this.hidePageLoading();
              });
        } catch {
          this.hidePageLoading();
        }
      },

      baixarRelatorioPdf() {
        if (!this.$validarFormulario()) {
          return;
        }

        const parametros = this.$montarParamsFilters(this.form.filtros);
        const servico = this.form.filtros.analitico
                      ? this.$obterRelatorioUnidadesEnsinoAnaliticoPdf
                      : this.$obterRelatorioUnidadesEnsinoSinteticoPdf;

        this.showPageLoading();
        try {
          servico({ ...parametros, responseType: 'blob' })
              .then(({ data }) => {
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(data);
                  link.setAttribute('download', 
                      this.form.filtros.analitico
                          ? 'SCAE.RelatórioUnidadeEnsinoAnalítico.pdf'
                          : 'SCAE.RelatórioUnidadeEnsinoSintético.pdf');
                    document.body.appendChild(link);
                    link.click();
              })
              .then(() => this.hidePageLoading())
              .catch((err) => {
                if (err.response.data instanceof Blob) {
                  const reader = new FileReader();
                  reader.addEventListener('loadend', (e) => {
                    const data = JSON.parse(e.srcElement.result);
                    this.notifyError(data.mensagem);
                  });
                  reader.readAsText(err.response.data);
                } else {
                  this.notifyError(err.message);
                }
                this.hidePageLoading();
              });
        } catch {
          this.$hidePageLoading();
        }
      },

      baixarRelatorioCvs() {
        if (!this.$validarFormulario()) {
          return;
        }

        const parametros = this.$montarParamsFilters(this.form.filtros);
        const servico = this.form.filtros.analitico
                      ? this.$obterRelatorioUnidadesEnsinoAnaliticoCvs
                      : this.$obterRelatorioUnidadesEnsinoSinteticoCvs;

        this.showPageLoading();
        try {
          servico({ ...parametros, responseType: 'blob' })
              .then(({ data }) => {
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(data);
                  link.setAttribute('download', 
                      this.form.filtros.analitico
                          ? 'SCAE.RelatórioUnidadeEnsinoAnalítico.csv'
                          : 'SCAE.RelatórioUnidadeEnsinoSintético.csv');
                  document.body.appendChild(link);
                  link.click();
              })
              .then(() => this.hidePageLoading())
              .catch((err) => {
                this.notifyError(err.message);
                this.hidePageLoading();
              });
        } catch {
          this.$hidePageLoading();
        }
      }
    },

    mounted() {
      this.$obterUnidadeRegional().then(({ data }) => {
        this.form.lists.unidadesRegionais = data.lista;
      });
      this.$obterDependenciaAdm().then(({ data }) => {
        this.form.lists.dependenciasAdministrativas = data.lista;
      });
      this.$obterSitFuncionamento().then(({ data }) => {
        this.form.lists.situacoesFuncionamento = data.lista;
      });
      this.$obterMunicipio().then(({ data }) => {
        this.form.lists.municipios = data.lista;
      });
    }
  }
</script>
