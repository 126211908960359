import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"persistent":"","width":"800"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,{staticClass:"not-margin pt-0 pr-0 pl-0"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c('div',{staticClass:"text-center"},[_c(VIcon,[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.edit ? "Editar" : "Cadastrar")+" Finalidade ")],1)]),_c('div',{staticClass:"container dialog"},[(_vm.visible)?_c(VForm,{ref:"form"},[_c(VRow,{staticClass:"d-flex justify-center mt-7"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Finalidade*","counter":"252","outlined":"","dense":"","rules":[function (v) { return _vm.$required(v); }, function (v) { return _vm.$max(v, 252); }],"maxlength":"252"},model:{value:(_vm.content.nome),callback:function ($$v) {_vm.$set(_vm.content, "nome", $$v)},expression:"content.nome"}})],1)],1)],1):_vm._e()],1),_c('scae-buttons',{attrs:{"actionText":_vm.edit ? 'Editar' : 'Salvar'},on:{"click":function($event){return _vm.salvar()},"cancel":function($event){return _vm.$emit('onClose')}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }