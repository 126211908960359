<template>
    <div class="page">
      <v-container fluid>
        <scae-breadcrumbs></scae-breadcrumbs>
        <v-form ref="form">
          <v-row class="d-flex justify-center mt-3">
            <v-col cols="4">
              <v-text-field
                label="Pergunta"
                v-model="form.filters.pergunta"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <scae-select-ativo
              :cols="4"
              v-model="form.filters.ativo"
            ></scae-select-ativo>
          </v-row>
          <div class="text-center">
            <v-col cols="12">
              <scae-tooltip
                :text="'Buscar'"
                :icon="'mdi-magnify'"
                @click="buscarFAQ()"
              ></scae-tooltip>
              <scae-tooltip
                :text="'Limpar'"
                :icon="'mdi-eraser'"
                @click="resetForm()"
              ></scae-tooltip>
              <scae-tooltip
                :text="'Incluir'"
                :icon="'mdi-plus'"
                @click="openDialogManterFAQ()"
              ></scae-tooltip>
            </v-col>
          </div>
        </v-form>
        <v-data-table
          class="mt-5"
          :headers="headers"
          :items="form.lists.listFAQ"
          :items-per-page="10"
          :footer-props="$footerProps"
          show-expand
        >
          <template v-slot:[`item.pergunta`]="{ item }">
            {{ item.pergunta }}
          </template>
          <template v-slot:[`item.posicao`]="{ item }">
            {{ item.posicao }}º
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <scae-label-ativo :ativo="item.ativo"></scae-label-ativo>
          </template>
          <template v-slot:[`item.acoes`]="{ item }">
            <scae-tooltip
              :text="'Editar FAQ'"
              :icon="'mdi-pencil'"
              :small="true"
              @click="openDialogManterFAQ(item)"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Visualizar FAQ'"
              :icon="'mdi-eye-outline'"
              :small="true"
              @click="openDialogVisualizarFAQ(item)"
            ></scae-tooltip>
            <scae-tooltip
              :text="`${item.ativo ? 'Inativar' : 'Ativar'} FAQ`"
              :icon="item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'"
              :color="item.ativo ? 'blue' : 'red'"
              :small="true"
              @click="inativarAtivarFAQ(item)"
            ></scae-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td class="resposta" :colspan="headers.length">
              {{ item.resposta }}
            </td>
          </template>
        </v-data-table>
      </v-container>
      <CadastrarEditarFAQDialog
        :visible="dialogManterFAQ.visible"
        :content="dialogManterFAQ.content"
        :edit="dialogManterFAQ.edit"
        :positions="getPosicoesFAQ"
        @onClose="dialogManterFAQ.close()"
        @onSave="buscarFAQ()"
      ></CadastrarEditarFAQDialog>
      <VisualizarFAQDialog
        :visible="dialogVisualizarFAQ.visible"
        :content="dialogVisualizarFAQ.content"
        @onClose="dialogVisualizarFAQ.close()"
      ></VisualizarFAQDialog>
    </div>
  </template>
  
  <script>
  import { Dialog, FAQ } from "~/commons/classes";
  import CadastrarEditarFAQDialog from "./dialogs/CadastrarEditarFAQDialog.vue";
  import VisualizarFAQDialog from "./dialogs/VisualizarFAQDialog.vue";
  
  export default {
    name: "ManterFAQView",
    components: {
      CadastrarEditarFAQDialog,
      VisualizarFAQDialog,
    },
    computed: {
      getPosicoesFAQ() {
        const proximaPosicao = Math.max(...this.form.lists.listFAQ.map((o) => o.posicao));
        return {
          lastPosition: proximaPosicao > 0 ? proximaPosicao + 1 : 1,
          allPositions: this.form.lists.listFAQ.map((o) => o.posicao),
        };
      },
    },
    data: () => ({
      dialogManterFAQ: Dialog.Build(),
      dialogVisualizarFAQ: Dialog.Build(),
      form: {
        filters: {
          pergunta: null,
          ativo: null,
        },
        lists: {
          listFAQ: []
        }
      },
      headers: [
        { text: "Pergunta", align: "center", value: "pergunta" },
        { text: "Posição", align: "center", value: "posicao" },
        { text: "Ativo", align: "center", value: "ativo" },
        { text: "Ações", align: "center", value: "acoes", sortable: false },
      ],
    }),
    methods: {
      openDialogManterFAQ(faq) {
        faq
          ? this.$visualizarDadosFAQ(faq).then(({ data }) => {
              this.dialogManterFAQ.open(data.faq, true);
            })
          : this.dialogManterFAQ.open(FAQ.Build());
      },
      openDialogVisualizarFAQ(faq) {
        this.$visualizarDadosFAQ(faq).then(
          ({ data }) => (this.dialogVisualizarFAQ.open(data.faq))
        );
      },
      buscarFAQ() {
        this.$buscarDadosFAQ({ params: this.form.filters }).then(
          ({ data }) => (this.form.lists.listFAQ = data.faqs)
        );
      },
      inativarAtivarFAQ(faq) {
        this.$swal({
          text: `Deseja ${
            faq.ativo ? "inativar" : "ativar"
          } a pergunta e resposta do FAQ?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          this.$inativarAtivarPerguntaFaq(
            { id: faq.id, ativo: !faq.ativo },
            faq.id
          ).then(({ data }) => {
            this.notifySuccess(data.mensagem);
            this.buscarFAQ();
          });
        });
      },
      resetForm() {
        this.$resetForm();
        this.form.lists.listFAQ = [];
      },
    },
  };
  </script>
  
  <style scoped>
  .resposta {
    white-space: pre-wrap;
    padding: 1em !important;
    text-align: left;
    font-weight: bold;
  }
  </style>