<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Usuário
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="4">
                <v-text-field
                  v-model="content.nome"
                  label="Nome do Usuário*"
                  counter="252"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 252)]"
                  :readonly="content.usuarioSadRh"
                  ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="content.cpf"
                  label="CPF*"
                  counter="14"
                  outlined
                  v-mask="'###.###.###-##'"
                  dense
                  :rules="[(v) => $required(v)]"
                  :readonly="content.usuarioSadRh"
                  ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="content.email"
                  label="E-mail*"
                  counter="252"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $email(v)]"
                  :readonly="!content.permitirAlterarEmail"
                  ></v-text-field>
              </v-col>
                <v-col cols="9">
                <v-autocomplete
                  v-model="content.perfis"
                  label="Perfil*"
                  outlined
                  dense
                  multiple
                  :items="form.lists.listPerfis"
                  :item-text="'nome'"
                  :item-value="'id'"
                  :rules="[
                    (p) => p.some((v) => $required(v)) || 'Campo obrigatório',
                  ]"
                ></v-autocomplete>
              </v-col>

              <v-col cols="3">
                <v-switch
                  v-model="content.usuarioSadRh"
                  :disabled="edit"
                  :true-value="true"
                  :false-value="false"
                  label="Usuário SADRH"
                  class="no-margin"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <scae-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="edit ? 'Editar' : 'Salvar'"
        ></scae-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { DialogProps, Usuario } from "~/commons/classes";

export default {
  props: new DialogProps(Usuario),
  data: () => ({
    form: {
      lists: {
        listPerfis: [],
      },
    },
    searchInput: null,
    visibleDialog: false,
  }),

  methods: {
    salvar() {
      const save = () => {
        return this.$swal({
          text: `Confirma ${this.edit ? "a edição" : "o cadastro"} do Usuário?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          this.content.cpf = this.$unMask(this.content.cpf);
          return this.edit
            ? this.$atualizarUsuario(this.content, this.content.id)
            : this.$cadastrarUsuario(this.content);
        });
      };

      if (this.$validarFormulario()) {
        save()
          .then(({ data }) => {
            this.notifySuccess(`${data.mensagem}<br/><i>Para consolidar as alterações, é necessário sair e entrar novamente no sistema!</i>`);
            this.$emit("onClose");
            this.$emit("onSave");
          }).catch(error => {
                this.notifyError(error.response.data.mensagem);})
      }
    },
  },

  created() {
    this.$obterPerfis({ params: { ativo: true } })
      .then(({ data }) => {
        this.form.lists.listPerfis = data.perfis;
      });
  },
};
</script>

<style scoped>
  .no-margin {
    margin-top: 0.4em;
    padding: 0;
  }
</style>

