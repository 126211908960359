<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Unidade de Ensino
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-tabs v-model="selectedTab" @change="mudarTabs()">
              <v-tab key="#identificacao">Identificação</v-tab>
              <v-tab key="#endereco">Endereço</v-tab>
              <v-tab key="#mantenedores">Mantenedor</v-tab>
              <v-tab key="#portaria">Portaria</v-tab>

              <v-tab-item key="#identificacao">
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="4">
                    <v-text-field
                      v-model="content.codigoMec"
                      label="Código MEC"
                      counter="8"
                      outlined
                      @keypress="$isNumber($event)"
                      dense
                      maxlength="8"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="content.codigo"
                      label="Cadastro Escolar"
                      outlined
                      dense
                      readonly
                      >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-information</v-icon>
                          </template>
                          <span>Este campo será preenchido automaticamente!</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="content.nomeUnidadeEnsino"
                      label="Unidade de Ensino*"
                      counter="70"
                      outlined
                      dense
                      :rules="[(v) => $required(v), (v) => $max(v, 70)]"
                      maxlength="70"
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="4">
                    <v-text-field
                      v-model="content.cnpj"
                      label="CNPJ*"
                      v-mask="'##.###.###/####-##'"
                      counter="18"
                      outlined
                      dense
                      :rules="[(v) => $required(v), v => validacaoCnpj(v)]"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="content.nomeEmpresarial"
                      label="Nome Empresarial*"
                      counter="100"
                      outlined
                      dense
                      :rules="[(v) => $required(v), (v) => $max(v, 100)]"
                      maxlength="100"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      label="Dependência Adm.*"
                      :items="form.lists.listDependenciaAdm"
                      :item-text="'nome'"
                      :item-value="'id'"
                      v-model="content.dependenciaAdministrativa.id"
                      outlined
                      dense
                      :rules="[v => $required(v)]"
                      ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="4">
                    <v-autocomplete
                      label="Sit. Funcionamento*"
                      :items="form.lists.listSitFuncionamento"
                      :item-text="'nome'"
                      :item-value="'id'"
                      v-model="content.situacaoFuncionamento.id"
                      outlined
                      dense
                      :rules="[v => $required(v)]"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      label="Unidade Regional*"
                      :items="form.lists.listUnidadeRegional"
                      :item-text="'nome'"
                      :item-value="'id'"
                      v-model="content.unidadeRegional.id"
                      outlined
                      dense
                      :rules="[v => $required(v)]"
                      @change="ajustarMunicipio(content.unidadeRegional.id)"
                      ></v-autocomplete>
                    </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      label="Identificação*"
                      :items="form.lists.listIdentificacao"
                      :item-text="'nome'"
                      :item-value="'id'"
                      multiple
                      v-model="content.identificacoes"
                      outlined
                      dense
                      return-object
                      :rules="[(p) => p.some((v) => $required(v)) || 'Campo obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item key="#endereco">
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="3">
                    <v-text-field
                      v-model="content.cep"
                      label="CEP*"
                      counter="9"
                      outlined
                      v-mask="'#####-###'"
                      dense
                      :rules="[(v) => $required(v), (v) => $max(v, 9)]"
                      @blur="buscarEnderecoPorCep"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="content.logradouro"
                      label="Logradouro*"
                      counter="70"
                      outlined
                      dense
                      :rules="[(v) => $required(v), (v) => $max(v, 70)]"
                      maxlength="70"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="content.numero"
                      label="Número*"
                      counter="5"
                      outlined
                      dense
                      :rules="[(v) => $required(v), (v) => $max(v, 5)]"
                      maxlength="5"
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="6">
                    <v-combobox
                      v-if="manipulacaoAutoCompleteInputRecife"
                      v-model="content.bairro"
                      label="Bairro*"
                      :item-text="'nome'"
                      :item-value="'nome'"
                      :items="form.lists.listBairros"                      
                      outlined
                      dense
                      :rules="[(v) => $required(v), (v) => $max(v, 50)]"
                    ></v-combobox>
                    <v-text-field
                      v-else
                      label="Bairro*"
                      counter="50"
                      :value="content.bairro"
                      outlined
                      dense
                      :rules="[(v) => $required(v), (v) => $max(v, 50)]"
                      maxlength="50"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="content.complemento"
                      label="Complemento*"
                      counter="50"
                      outlined
                      dense
                      :rules="[(v) => $required(v), (v) => $max(v, 50)]"
                      maxlength="50"
                      ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="4">
                    <v-autocomplete
                      label="Município*"
                      :items="form.lists.listMunicipio"
                      :item-text="'nome'"
                      :item-value="'id'"
                      v-model="content.municipio.id"
                      outlined
                      dense
                      :rules="[v => $required(v)]"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="UF*"
                      v-model="content.uf"
                      outlined
                      dense
                      readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      label="Localização*"
                      :items="form.lists.listLocalizacao"
                      :item-text="'nome'"
                      :item-value="'id'"
                      v-model="content.localizacao.id"
                      outlined
                      dense
                      :rules="[v => $required(v)]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item key="#mantenedores">
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="10">
                    <v-autocomplete
                      v-model="content.mantenedorId"
                      label="Mantenedor"
                      :items="form.lists.listaMantendedores"
                      :item-text="item => item.nome ? item.nome : ''"
                      :item-value="'id'"
                      outlined
                      dense
                      @change="atualizarCpfCnpjMantenedor"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <scae-tooltip
                      :text="'Incluir um Mantenedor'"
                      :icon="'mdi-plus'"
                      @click="openDialogManterMantenedores()"
                    ></scae-tooltip>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="4">
                    <v-text-field
                      label="CPF/CNPJ"
                      v-model="content.cpfCnpjMantenedor"
                      outlined
                      dense
                      readonly
                      ></v-text-field>
                  </v-col>
                  <v-col cols="8">
                    <v-btn
                      color="primary"
                      @click="adicionarAtualizarMantenedor()"
                    >
                    {{ modoEdicao ? 'Atualizar' : 'Adicionar' }} Mantenedor
                    </v-btn>
                  </v-col>
                </v-row>
                <v-data-table
                  class="mt-5"
                  :headers="headers"
                  :items="content.relacaoMantenedores"
                  :items-per-page="10"
                  :footer-props="$footerProps"
                >
                  <template v-slot:[`item.nomeMantenedor`]="{ item }">
                    {{ item.nomeMantenedor  }}
                  </template>
                  <template v-slot:[`item.cpfCnpjMantenedor`]="{ item }">
                    <div class="report">
                        {{ $formatCpfCnpj(item.cpfCnpjMantenedor) }}
                    </div>
                  </template>
                  <template v-slot:[`item.acoes`]="{ item, index}">
                    <div :key="index">
                      <scae-tooltip
                        :text="'Editar Mantenedor'"
                        :icon="'mdi-pencil'"
                        :small="true"
                        @click="editarMantenedor(item, index)"
                      ></scae-tooltip>
                      <scae-tooltip
                        :text="`${item.ativo ? 'Inativar' : 'Ativar'} Mantenedor`"
                        :icon="item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'"
                        :color="item.ativo ? 'blue' : 'red'"
                        :small="true"
                        @click="inativarAtivarMantenedores(item)"
                      ></scae-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>

              <v-tab-item key="#portaria">
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="6">
                    <v-text-field
                      v-model="content.numeroPortaria"
                      label="Portaria*"
                      @keypress="$verificarCaracterPortaria($event)"
                      counter="15"
                      outlined
                      dense
                      maxlength="15"
                      :rules="[(v) => $required(v)]"
                    ></v-text-field>
                  </v-col>
                  <scae-date-picker
                    v-model="content.dataDo"
                    :dataEdicao="$formatarData(content.dataDo)"
                    label="Data DO*"
                    cols="6"
                    ref="dataPicker"
                    :rules="[(v) => $required(v)]"
                  ></scae-date-picker>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="5">
                    <v-text-field
                      v-model="content.sei"
                      label="SEI"
                      counter="25"
                      maxlength="25"
                      outlined
                      dense
                      @keypress="formatarSei($event)"
                      :rules="[v => validacaoSei(v)]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                      label="Finalidade*"
                      :items="form.lists.listFinalidades"
                      :item-text="'nome'"
                      :item-value="'id'"
                      v-model="content.finalidade"
                      multiple
                      outlined
                      return-object
                      dense
                      :rules="[(p) => !p || p.some((v) => $required(v)) || 'Campo obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="2">
                    <scae-tooltip
                      :text="'Incluir uma Finalidade'"
                      :icon="'mdi-plus'"
                      @click="openDialogManterFinalidade()"
                    ></scae-tooltip>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center mt-7">
                  <v-col cols="8">
                    <v-autocomplete
                      label="Oferta de Ensino*"
                      :items="form.lists.listOfertaEnsino"
                      :item-text="'nome'"
                      :item-value="'id'"
                      v-model="content.ofertaDeEnsino"
                      outlined
                      multiple
                      return-object
                      dense
                      :rules="[(p) => !p || p.some((v) => $required(v)) || 'Campo obrigatório']"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      color="primary"
                      @click="adicionarAtualizarPortaria()"
                      >
                    {{ modoEdicao ? 'Atualizar' : 'Adicionar' }} Portaria
                    </v-btn>
                  </v-col>
                </v-row>
                <v-data-table
                  class="mt-5"
                  :headers="headersPortaria"
                  :items="content.portarias"
                  :items-per-page="10"
                  :footer-props="$footerProps"
                >
                  <template v-slot:[`item.numeroPortaria`]="{ item }">
                    {{ item.numeroPortaria }}
                  </template>
                  <template v-slot:[`item.dataDo`]="{ item }">
                    {{ $formatarData(item.dataDo) }} 
                  </template>
                  <template v-slot:[`item.sei`]="{ item }">
                      {{ $formatSEI(item.sei) }}
                    </template>
                    <template v-slot:[`item.finalidade`]="{ item }">
                    {{ mapearIdsParaNomes(item.finalidades, form.lists.listFinalidades) }}
                  </template>
                  <template v-slot:[`item.ofertaDeEnsino`]="{ item }">
                    {{ mapearIdsParaNomes(item.ofertasEnsino, form.lists.listOfertaEnsino) }}
                  </template>
                  <template v-slot:[`item.acoes`]="{ item, index }">
                    <div :key="index">
                      <scae-tooltip
                        :text="'Editar Portaria'"
                        :icon="'mdi-pencil'"
                        :small="true"
                        @click="editarPortaria(item, index)"
                        ></scae-tooltip>
                      <scae-tooltip
                        :text="`${item.ativo ? 'Inativar' : 'Ativar'} Portaria`"
                        :icon="item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'"
                        :color="item.ativo ? 'blue' : 'red'"
                        :small="true"
                        @click="inativarAtivarPortaria(item)"
                      ></scae-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-form>
        </div>
        <scae-buttons
          @click="salvar()"
          @cancel="cancelar()"
          :actionText="'Salvar'"
        ></scae-buttons>
      </v-card>
    </v-dialog>
    <CadastrarEditarMantenedoresUnidadeEnsinoDialog
        :visible="dialogManterMantenedoresUnidadeEnsinoDialog.visible"
        :content="dialogManterMantenedoresUnidadeEnsinoDialog.content"
        @onClose="dialogManterMantenedoresUnidadeEnsinoDialog.close()"
        @onSave="obterAtualizarMantenedores()"
    ></CadastrarEditarMantenedoresUnidadeEnsinoDialog>
    <CadastrarEditarFinalidadeUnidadeEnsinoDialog
        :visible="dialogManterFinalidadeUnidadeEnsinoDialog.visible"
        :content="dialogManterFinalidadeUnidadeEnsinoDialog.content"
        @onClose="dialogManterFinalidadeUnidadeEnsinoDialog.close()"
        @onSave="obterAtualizarFinalidades()"
    ></CadastrarEditarFinalidadeUnidadeEnsinoDialog>
  </div>
</template>

<script>
  import pageLoadingMixin from "mixins/page-loading";

  import { DialogProps, UnidadeDeEnsino, Dialog, MantenedoresUnidadeDeEnsino, FinalidadeUnidadeDeEnsino } from "~/commons/classes";

  import CadastrarEditarMantenedoresUnidadeEnsinoDialog from "./CadastrarEditarMantenedoresUnidadeEnsinoDialog.vue";
  import CadastrarEditarFinalidadeUnidadeEnsinoDialog from "./CadastrarEditarFinalidadeUnidadeEnsinoDialog.vue";
  
  export default {
    name: "ManterUnidadeEnsinoView",
    props: new DialogProps(UnidadeDeEnsino),
    components: {
      CadastrarEditarMantenedoresUnidadeEnsinoDialog,
      CadastrarEditarFinalidadeUnidadeEnsinoDialog,
    },
    mixins: [pageLoadingMixin],
    data: () => ({
      dialogManterMantenedoresUnidadeEnsinoDialog: Dialog.Build(),
      dialogManterFinalidadeUnidadeEnsinoDialog: Dialog.Build(),
      modoEdicao: false,
      index: Number,
      selectedTab: '#identificacao',
      form: {
        lists: {
          listDependenciaAdm: [],
          listSitFuncionamento: [],
          listUnidadeRegional: [],
          listIdentificacao: [],
          listMunicipio: [],
          listUf: [],
          listLocalizacao: [],
          listMantenedores: [],
          listFinalidades: [],
          listOfertaEnsino: [],
          listaMantendedores: [],
          listaPortaria: [],
          portarias: [],
          mantenedores: []
        }
      },
      headers: [
        { text: "Mantenedor", align: "center", value: "nomeMantenedor" },
        { text: "CPF/CNPJ", align: "center", value: "cpfCnpjMantenedor" },
        { text: "Ações", align: "center", value: "acoes", sortable: false }
      ],
      headersPortaria: [
        { text: "Portaria", align: "center", value: "numeroPortaria" },
        { text: "Data DO", align: "center", value: "dataDo" },
        { text: "SEI", align: "center", value: "sei" },
        { text: "Finalidade", align: "center", value: "finalidade" },
        { text: "Oferta de Ensino", align: "center", value: "ofertaDeEnsino" },
        { text: "Ações", align: "center", value: "acoes", sortable: false }
      ]
    }),
    computed: {
      manipulacaoAutoCompleteInputRecife() {
        const unidadeRegionalId = this.content.unidadeRegional?.codigo;

        const gerenciarBairro = ['3055', '3056'].includes(unidadeRegionalId);

        if (gerenciarBairro) {
          this.manipulandoDadosGeMunicipioBairro();
        } else {
          this.manipulandoDadosGeMunicipioBairro();
        }
        return gerenciarBairro;
      },
    },
    methods: {
      updateContentBairro(value) {
        this.content.bairro = value;
      },
      salvar() {
        const save = () => {
          return this.$swal({
            text: `Deseja confirmar ${
              this.edit ? "a edição" : "o cadastro"
            } da Unidade de Ensino?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Cancelar`,
          }).then(() => {
            this.content.cnpj = this.$unMaskCnpj(this.content.cnpj);
            this.content.cep = this.content.cep.replace(/\D/g, '');

            return this.edit
              ? this.$editarUnidadeDeEnsino(new UnidadeDeEnsino(this.content).format())
              : this.$cadastrarUnidadeDeEnsino(new UnidadeDeEnsino(this.content).format());
          });
        };

        if (this.validarCamposObrigatorios()) {
          this.showPageLoading();
          save().then(({ data }) => {
            this.notifySuccess(data.mensagem);
            this.$emit('onClose');
            this.$emit('onSave');
          })
          .catch((error) => {
            this.content.cnpj = this.$formatCnpj(this.content.cnpj);
            this.notifyError(error.response.data.mensagem);
          })
          .finally(() => {
            this.hidePageLoading()
            this.selectedTab = '#identificacao';
          });
        } else {
          this.$refs.form.validate();
        }
      },

      cancelar() {
        this.selectedTab = '#identificacao';
        this.$emit("onClose");
      },

      validarCamposObrigatorios() {
        const camposNaoPreenchidosIdentificacao = [
              this.content.nomeUnidadeEnsino,
              this.content.cnpj,
              this.content.nomeEmpresarial,
              this.content.dependenciaAdministrativa?.id,
              this.content.situacaoFuncionamento?.id,
              this.content.unidadeRegional?.id,
              this.content.identificacoes
            ].filter(v => !v || (Array.isArray(v) && !v.length));

        const camposNaoPreenchidosEndereco = [
              this.content.cep,
              this.content.logradouro,
              this.content.numero,
              this.content.bairro,
              this.content.complemento,
              this.content.municipio.id,
              this.content.uf,
              this.content.localizacao?.id
            ].filter(v => !v);

        if (camposNaoPreenchidosIdentificacao.length > 0 && camposNaoPreenchidosEndereco.length > 0) {
          this.notifyWarning('Preencha os campos obrigatórios das abas de Identificação e Endereço.');
          return false;
        } 
        
        if (camposNaoPreenchidosIdentificacao.length > 0) {
          this.notifyWarning('Preencha os campos obrigatórios da aba de Identificação.');
          return false;
        } 
        
        if (camposNaoPreenchidosEndereco.length > 0) {
          this.notifyWarning('Preencha os campos obrigatórios da aba de Endereço.');
          return false;
        }

        const cnpjValido = this.validacaoCnpj(this.content.cnpj);
        if (typeof cnpjValido === 'string') {
          this.notifyWarning('O CNPJ informado não é válido.');
          this.selectedTab = '#identificacao';
          return false;
        }

        return true;
      },

      buscarEnderecoPorCep() {
        const cepSemMascara = this.content.cep.replace(/\D/g, '');

        this.$obterCep({ params: { cep: cepSemMascara } })
        .then((response) => {
          if (!response.data.sucesso) {
            this.notifyError('O CEP informado não é valido.');
          }

          const e = response.data.endereco;
          if (!(e || e.cep || e.logradouro || e.bairro || e.municipio || e.uf)) {
            this.notifyError('O CEP informado não é valido.');
            return;
          }

          this.content.cep = e.cep;
          this.content.logradouro = e.logradouro;
          this.content.bairro = e.bairro;
        }).catch (() => {
          this.notifyError('Ocorreu um erro consultando a API de CEP.');
        });
      },

      // ABA Portaria
      adicionarAtualizarPortaria() {
        if (
          !this.content.numeroPortaria ||
          !this.content.dataDo ||
          !this.content.finalidade ||
          !this.content.ofertaDeEnsino
        ) {
          return this.notifyWarning('Preencha os campos da aba de Portaria.');
        }

        const ativo = !this.content.portariaExistente || this.content.portariaExistente.ativo;

        if (this.content.portarias
                  .filter((_, i) => !(this.modoEdicao && i === this.indiceEdicao))
                  .map(p => p.numeroPortaria)
                  .values()
                  .some(v => v === this.content.numeroPortaria)) {
          return this.notifyError('Já existe uma portaria com este número.');
        }

        // Filtra IDs indefinidos e remove objetos Vue do array
        const finalidadesIdsFiltrados = this.content.finalidade
          .map(finalidade => (finalidade && typeof finalidade === 'object' ? finalidade.id : finalidade))
          .filter(id => id !== undefined && typeof id !== 'object');

        const ofertasEnsinoIdsFiltrados = this.content.ofertaDeEnsino
          .map(oferta => (oferta && typeof oferta === 'object' ? oferta.id : oferta))
          .filter(id => id !== undefined && typeof id !== 'object');

        const novaPortaria = {
          id: this.content.idPortaria,
          numeroPortaria: this.content.numeroPortaria,
          dataDo: this.content.dataDo,
          sei: this.content.sei,
          finalidades: finalidadesIdsFiltrados,
          ofertasEnsino: ofertasEnsinoIdsFiltrados,
          ativo: ativo
        };

        if (this.modoEdicao) {
          this.content.portarias.splice(this.indiceEdicao, 1, novaPortaria);
          this.modoEdicao = false;
          this.indiceEdicao = -1;
        } else {
          this.content.portarias.push(novaPortaria);
        }

        this.limparCamposPortaria();
      },

      mapearIdsParaNomes(ids, lista) {
        if (!ids || !lista || !Array.isArray(ids) || !Array.isArray(lista)) {
          return '';
        }

        const nomes = ids.map(id => {
          const item = lista.find(item => item.id === id);
          return item ? item.nome : null;
        });

        return nomes.join(', ');
      },
      editarPortaria(item, index) {
        this.limparCamposPortaria();
        this.$forceUpdate();
        
        this.content.idPortaria = item.id ?? 0;
        this.content.numeroPortaria = item.numeroPortaria;
        this.content.dataDo = item.dataDo;
        this.content.sei = this.$formatSEI(item.sei);
        this.content.finalidade = item.finalidades;
        this.content.ofertaDeEnsino = item.ofertasEnsino;

        this.modoEdicao = true;
        this.indiceEdicao = index;
      },
      inativarAtivarPortaria(item) {
        item.ativo = !item.ativo;
      },
      limparCamposPortaria() {
        this.content.idPortaria = 0;
        this.content.numeroPortaria = null;
        this.content.dataDo = null;
        this.content.sei = null;
        this.content.finalidade = null;
        this.content.ofertaDeEnsino = null;
      },
      obterAtualizarOfertaEnsino() {
        this.$buscarOfertasDeEnsinoAtivas({params: {ativo: true}}).then(({ data }) => {
          this.form.lists.listOfertaEnsino  = data.ofertasDeEnsino;
        });
      },
      obterAtualizarFinalidades() {
        this.$buscarFinalidadesAtivas({params: {ativo: true}}).then(({ data }) => {
          this.form.lists.listFinalidades  = data.finalidades;
        });
      },
      openDialogManterFinalidade() {
        this.dialogManterFinalidadeUnidadeEnsinoDialog.open(FinalidadeUnidadeDeEnsino.Build());
      },

      // ABA Mantenedores
      adicionarAtualizarMantenedor() {
        if (!this.content.nomeMantenedor) {
          this.$refs.form.validate();
          return this.notifyWarning('Selecione um Mantenedor!');
        }

        this.atualizarCpfCnpjMantenedor();

        const mantenedorExistente = this.content.relacaoMantenedores.find(m => m.mantenedorId === this.content.mantenedorId);

        if (mantenedorExistente) {
          this.notifyWarning('Mantenedor já existe na tabela.');
          return;
        }

        const ativo = !mantenedorExistente || mantenedorExistente.ativo;

        const novoMantenedor = {
          id: this.content.relacaoMantenedorId ?? 0,
          MantenedorId: this.content.mantenedorId,
          nomeMantenedor: this.content.nomeMantenedor,
          cpfCnpjMantenedor: this.content.cpfCnpjMantenedor,
          ativo: ativo
        };

        if (this.modoEdicao) {
          this.content.relacaoMantenedores.splice(this.indiceEdicao, 1, novoMantenedor);
          this.modoEdicao = false;
          this.indiceEdicao = -1;
        } else {
          this.content.relacaoMantenedores.push(novoMantenedor);
        }

        this.limparCamposMantenedor();
      },

      editarMantenedor(item, index) {
        this.limparCamposMantenedor();

        if (!this.form.lists?.listaMantendedores || !item?.nomeMantenedor) {
          return;
        }

        const mantenedor = this.form.lists.listaMantendedores.find(m => m.nome === item.nomeMantenedor);
        if (!mantenedor) {
          this.$forceUpdate();
          return;
        }

        this.content.relacaoMantenedorId = item.id ?? 0;
        this.content.mantenedorId = item.mantenedorId;
        this.content.nomeMantenedor = item.nomeMantenedor;
        this.content.cpfCnpjMantenedor = this.$formatCpfCnpj(item.cpfCnpjMantenedor);

        this.modoEdicao = true;
        this.indiceEdicao = index;

        this.$forceUpdate();
      },
      limparCamposMantenedor() {
        this.content.relacaoMantenedorId = 0;
        this.content.cpfCnpjMantenedor = null;
        this.content.nomeMantenedor = null;
        this.content.mantenedorId = 0;
        this.content.relacaoMantenedorId = null;
      },
      atualizarCpfCnpjMantenedor() {
        const selectedMantenedor = this.form.lists.listaMantendedores.find(
          (m) => m.id === this.content.mantenedorId
        );
          
        if (selectedMantenedor) {
          this.content.mantenedorId = selectedMantenedor.id ?? 0;
          this.content.nomeMantenedor = selectedMantenedor.nome;
          this.content.cpfCnpjMantenedor = this.$formatCpfCnpj(selectedMantenedor.cpfCnpj);
          this.$forceUpdate();
        }
      },
      openDialogManterMantenedores() {
         this.dialogManterMantenedoresUnidadeEnsinoDialog.open(MantenedoresUnidadeDeEnsino.Build());
      },
      inativarAtivarMantenedores(item) {
        item.ativo = !item.ativo;
      },
      obterAtualizarMantenedores() {
        this.$obterMantenedor({ params: { ativo: true } }).then(({ data }) => {
          this.form.lists.listaMantendedores = data.listaMantendedores;
        });
      },
      manipulandoDadosGeMunicipioBairro() {
        this.$obterUnidadeRegional().then(({ data }) => {
          this.form.lists.listUnidadeRegional = data.lista;
        });

        const unidadeRegionalId = this.content.unidadeRegional?.id;

        this.configurarFormulario(unidadeRegionalId);
      },
      validacaoCnpj(v) {
        if (!v) {
          return true;
        }

        const cleanedValue = v.replace(/\D/g, '');

        if (cleanedValue.length !== 14) {
          return 'O CNPJ informado não é válido.';
        }

        if (/^(\d)\1+$/.test(cleanedValue)) {
          return 'O CNPJ informado não é válido.';
        }

        if (!this.$validateCnpj(cleanedValue)) {
          return 'O CNPJ informado não é válido.';
        }

        return true;
      },
      validacaoSei(v) {
        if (!v) {
          return true;
        }

        const cleanedValue = v.replace(/\D/g, '');

        if (cleanedValue.length !== 22) {
          return 'SEI inválido';
        }

        return true;
      },
      formatarSei(evento) {
        if (evento.key === 8 || evento.key === 46) {
          return true;
        }

        if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '.', '/'].includes(evento.key)) {
          evento.preventDefault();
        }

        if (!this.content.sei) {
          this.content.sei = '';
        }
        
        const digitos = this.content.sei.replace(/[^\d]/g, '');

        if (digitos.length >= 20) {
          this.content.sei = digitos.replace(/(\d{10})(\d{6})(\d{4})(\d{0,2})/, '$1.$2/$3-$4');
        } else if (digitos.length >= 16) {
          this.content.sei = digitos.replace(/(\d{10})(\d{6})(\d{0,4})/, '$1.$2/$3');
        } else if (digitos.length >= 10) {
          this.content.sei = digitos.replace(/(\d{10})(\d{0,6})/, '$1.$2');
        }

        this.$forceUpdate();
      },
      ajustarMunicipio(unidadeRegionalId) {
        if (!unidadeRegionalId) {
          return;
        }
        if (unidadeRegionalId === 1) {
          this.content.municipio.id = 7;
        }
        if (unidadeRegionalId === 2) {
          this.content.municipio.id = 8;
        }
        this.configurarFormulario(unidadeRegionalId);
      },
      configurarFormulario(unidadeRegionalId) {
        // Verificar se um código está presente e é diferente de 1 e 2
        if (unidadeRegionalId && unidadeRegionalId !== 1 && unidadeRegionalId !== 2) {
          // Caso unidadeRegionalId seja diferente de 1 e 2, carrega a lista completa de municípios
          this.$obterMunicipio().then(({ data }) => {
            this.form.lists.listMunicipio = data.lista;

            // Limpar a lista de bairros se nenhum código estiver presente
            this.form.lists.listBairros = [];
          });
        } else if (unidadeRegionalId) {
          // Caso unidadeRegionalId seja 1 ou 2
          this.$obterMunicipio({ params: { unidadeRegionalId: unidadeRegionalId } }).then(({ data }) => {
            this.form.lists.listMunicipio = data.lista;

            // Verificar se um município foi selecionado
            if (this.content.municipio) {
              // Lógica para carregar lista de Bairros com base no Município e Unidade Regional selecionados
              if (unidadeRegionalId === 1) {
                this.$obterBairros({ params: { municipioId: 7 } }).then(({ data }) => {
                  this.form.lists.listBairros = data.lista;
                });
              } else if (unidadeRegionalId === 2) {
                this.$obterBairros({ params: { municipioId: 8 } }).then(({ data }) => {
                  this.form.lists.listBairros = data.lista;
                });
              }
            }
          });
        } else {
          // Caso nenhum código esteja presente
          this.form.lists.listMunicipio = [];
          this.form.lists.listBairros = [];
        }
      },
      mudarTabs() {
        this.limparCamposMantenedor();
        this.limparCamposPortaria();

        this.modoEdicao = false;
        this.indiceEdicao = -1;
      }
    },

    mounted() {
      this.obterAtualizarMantenedores();
      this.obterAtualizarFinalidades();
      this.obterAtualizarOfertaEnsino();

      const params = { params: { ativo: true } };

      this.$obterDependenciaAdm(params).then(({ data }) => {
        this.form.lists.listDependenciaAdm = data.lista;
      });
      this.$obterSitFuncionamento(params).then(({ data }) => {
        this.form.lists.listSitFuncionamento = data.lista;
      });
      this.$obterIdentificacao(params).then(({ data }) => {
        this.form.lists.listIdentificacao = data.lista;
      });
      this.$obterLocalizacao().then(({ data }) => {
        this.form.lists.listLocalizacao = data.lista;
      });

      this.form.lists.listUf = [{ 'id': 1, 'nome': 'PE' }];

      this.$obterUnidadeRegional().then(({ data }) => {
        this.form.lists.listUnidadeRegional = data.lista;
      });
    }
  };
</script>
