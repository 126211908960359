<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="800">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">
            <v-icon>mdi-pencil</v-icon>
            {{ edit ? "Editar" : "Cadastrar" }} Mantenedor
          </div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form" v-if="visible">
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="12">
                <v-text-field
                  v-model="content.nome"
                  label="Nome do Mantenedor*"
                  counter="252"
                  outlined
                  dense
                  :rules="[(v) => $required(v), (v) => $max(v, 252)]"
                  maxlength="252"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-7">
              <v-col cols="8">
                <v-text-field
                  label="CPF/CNPJ*"
                  v-model="content.cpfCnpj"
                  outlined
                  dense
                  counter="18"                      
                  v-mask="getMask(content.cpfCnpj)"
                  :rules="[(v) => $required(v), v => validacaoCpfCnpj(v)]"
                  maxlength="18"
                ></v-text-field>
              </v-col>
              <scae-select-qualificacao
                :cols="4"
                v-model="content.qualificacaoId">
              </scae-select-qualificacao>
            </v-row>
          </v-form>
        </div>
        <scae-buttons
          @click="salvar()"
          @cancel="$emit('onClose')"
          :actionText="edit ? 'Editar' : 'Salvar'"
        ></scae-buttons>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
  import { DialogProps, MantenedoresUnidadeDeEnsino } from '~/commons/classes';
  export default {
    props: new DialogProps(MantenedoresUnidadeDeEnsino),
    methods: {
      salvar() {
        const save = () => {
          return this.$swal({
            text: `Confirma ${
              this.edit ? "a edição" : "o cadastro"
            } do Mantenedor?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Cancelar`,
          }).then(() => {
            this.content.cpfCnpj = this.$unMask(this.content.cpfCnpj);

            return this.$cadastrarMantenedor(this.content);
          });
        };
        if (this.$validarFormulario()) {
          save().then(({ data }) => {
            this.notifySuccess(data.mensagem);
            this.$emit("onClose");
            this.$emit("onSave");
          })
          .catch((error) => {
            this.notifyError(error.response.data.mensagem);
          })
        }
      },
      getMask(cpfCnpj) {
          if (cpfCnpj) {
            const cleanedValue = cpfCnpj.replace(/\D/g, '');
            return cleanedValue.length === 11 ? '###.###.###-##' : '##.###.###/####-##';
          }
          return '';
      },
      getCounter(cpfCnpj) {
          if (cpfCnpj) {
            const digitCount = cpfCnpj.replace(/\D/g, '').length;
            const maxLength = digitCount === 11 ? 14 : 18;
            return `${maxLength}`;
          }
          return '';
      },
      validacaoCpfCnpj(v) {
          if (!v) {
              return 'Campo obrigatório';
          }

          const cleanedValue = v.replace(/\D/g, '');
          const digitCount = cleanedValue.length;

          // Verificar se o CPF/CNPJ tem a quantidade mínima de dígitos
          if (digitCount != 11 && digitCount != 14) {
            return 'CPF/CNPJ inválido';
          }

          // Verificar se todos os dígitos são iguais, o que indica um número inválido
          if (/^(\d)\1+$/.test(cleanedValue)) {
            return 'CPF/CNPJ inválido';
          }

          // Verificar se é um CPF válido
          if (digitCount === 11 && !this.$validateCpf(cleanedValue)) {
            return 'CPF inválido';
          }

          // Verificar se é um CNPJ válido
          if (digitCount === 14 && !this.$validateCnpj(cleanedValue)) {
            return 'CNPJ inválido';
          }

          // Se passou por todas as verificações, é válido
          return true;
      }
    }
  };
</script>
  