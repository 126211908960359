import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"persistent":"","width":"800"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c(VCard,{staticClass:"not-margin pt-0 pr-0 pl-0"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c('div',{staticClass:"text-center"},[_c(VIcon,[_vm._v("mdi-eye")]),_vm._v(" Visualizar Perfil ")],1)]),_c('div',{staticClass:"container dialog"},[_c(VRow,{staticClass:"d-flex justify-center mt-7"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"value":_vm.content.nome,"label":"Perfil","readonly":"","outlined":"","dense":""}})],1)],1),_c(VRow,{staticClass:"d-flex justify-center"},[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"small"},[_vm._v("Permissões por Grupo")]),_vm._l((this.content.grupos),function(grupo){return _c('div',{key:grupo.id},[_c(VCard,{staticClass:"border"},[_c(VCardTitle,[_c(VIcon,{staticClass:"grupo",attrs:{"small":""}},[_vm._v("mdi-account-group")]),_vm._v(_vm._s(grupo.nome)+" ")],1),_c(VChipGroup,{attrs:{"active-class":"primary--text","column":""}},_vm._l((grupo.permissoes),function(permissao){return _c(VChip,{key:permissao.id},[_c(VIcon,{staticClass:"grupo",attrs:{"small":""}},[_vm._v("mdi-ticket-percent")]),_vm._v(_vm._s(permissao.nome)+" ")],1)}),1)],1)],1)})],2)],1),_c('div',{staticClass:"btn-group text-center mt-5"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('onClose')}}},[_c(VIcon,[_vm._v("mdi-close")]),_vm._v(" Fechar ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }