import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c(VContainer,{attrs:{"fluid":""}},[_c('scae-breadcrumbs'),_c(VForm,{ref:"form"},[_c(VRow,{staticClass:"d-flex justify-center mt-3"},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"label":"Pergunta","outlined":"","dense":""},model:{value:(_vm.form.filters.pergunta),callback:function ($$v) {_vm.$set(_vm.form.filters, "pergunta", $$v)},expression:"form.filters.pergunta"}})],1),_c('scae-select-ativo',{attrs:{"cols":4},model:{value:(_vm.form.filters.ativo),callback:function ($$v) {_vm.$set(_vm.form.filters, "ativo", $$v)},expression:"form.filters.ativo"}})],1),_c('div',{staticClass:"text-center"},[_c(VCol,{attrs:{"cols":"12"}},[_c('scae-tooltip',{attrs:{"text":'Buscar',"icon":'mdi-magnify'},on:{"click":function($event){return _vm.buscarFAQ()}}}),_c('scae-tooltip',{attrs:{"text":'Limpar',"icon":'mdi-eraser'},on:{"click":function($event){return _vm.resetForm()}}}),_c('scae-tooltip',{attrs:{"text":'Incluir',"icon":'mdi-plus'},on:{"click":function($event){return _vm.openDialogManterFAQ()}}})],1)],1)],1),_c(VDataTable,{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.form.lists.listFAQ,"items-per-page":10,"footer-props":_vm.$footerProps,"show-expand":""},scopedSlots:_vm._u([{key:"item.pergunta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pergunta)+" ")]}},{key:"item.posicao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.posicao)+"º ")]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('scae-label-ativo',{attrs:{"ativo":item.ativo}})]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('scae-tooltip',{attrs:{"text":'Editar FAQ',"icon":'mdi-pencil',"small":true},on:{"click":function($event){return _vm.openDialogManterFAQ(item)}}}),_c('scae-tooltip',{attrs:{"text":'Visualizar FAQ',"icon":'mdi-eye-outline',"small":true},on:{"click":function($event){return _vm.openDialogVisualizarFAQ(item)}}}),_c('scae-tooltip',{attrs:{"text":((item.ativo ? 'Inativar' : 'Ativar') + " FAQ"),"icon":item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch',"color":item.ativo ? 'blue' : 'red',"small":true},on:{"click":function($event){return _vm.inativarAtivarFAQ(item)}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"resposta",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.resposta)+" ")])]}}],null,true)})],1),_c('CadastrarEditarFAQDialog',{attrs:{"visible":_vm.dialogManterFAQ.visible,"content":_vm.dialogManterFAQ.content,"edit":_vm.dialogManterFAQ.edit,"positions":_vm.getPosicoesFAQ},on:{"onClose":function($event){return _vm.dialogManterFAQ.close()},"onSave":function($event){return _vm.buscarFAQ()}}}),_c('VisualizarFAQDialog',{attrs:{"visible":_vm.dialogVisualizarFAQ.visible,"content":_vm.dialogVisualizarFAQ.content},on:{"onClose":function($event){return _vm.dialogVisualizarFAQ.close()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }