<template>
    <div class="text-center">
      <v-dialog persistent v-model="visible" width="800">
        <v-card class="not-margin pt-0 pr-0 pl-0">
          <v-toolbar dark color="primary">
            <div class="text-center">
              <v-icon>mdi-pencil</v-icon>
              {{ edit ? "Editar" : "Cadastrar" }} Perfil
            </div>
          </v-toolbar>
          <div class="container dialog">
            <v-form ref="form" v-if="visible">
              <v-row class="d-flex justify-center mt-7">
                <v-col cols="9">
                  <v-text-field
                    v-model="content.nome"
                    label="Nome"
                    counter="252"
                    outlined
                    dense
                    :rules="[(v) => $required(v), (v) => $max(v, 252)]"
                    @keydown.enter.prevent="onEnterPressed"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-card class="border">
                    <v-card-title class="d-flex justify-center">
                      <v-icon small class="pr-1">mdi-group</v-icon> Selecionar Grupo(s)
                    </v-card-title>
                    <div v-if="!form.lists.grupos.length" :class="getClassAuxiliarGruposSelecionados">
                      Arraste até aqui. <v-icon small>mdi-arrow-down-bold</v-icon>
                    </div>
                  </v-card>
                  <div class="drag-button mt-3">
                    <v-btn @click="moverTodas()" color="primary" outlined small>
                      Adicionar Todos
                    </v-btn> 
                  </div>
                  <draggable class="drag-item" group="grupos" @change="verify($event)" v-model="form.lists.grupos">
                    <v-card class="drag-card" v-for="(item, i) in form.lists.grupos" :key="i">
                      <span>{{ item.nome }}</span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-chat-question</v-icon>
                          </v-btn>
                        </template>
                        Clique para arrastar e soltar no local desejado
                      </v-tooltip>
                    </v-card>
                  </draggable>
                </v-col>
                <v-col cols="6">
                  <v-card class="border">
                    <v-card-title class="d-flex justify-center">
                      <v-icon small class="pr-1">mdi-select-group</v-icon> Grupo(s) Selecionados
                    </v-card-title>
                    <div v-if="!content.grupos.length" :class="getClassAuxiliarGrupos">
                      Arraste até aqui. <v-icon small>mdi-arrow-down-bold</v-icon>
                    </div>
                  </v-card>
                  <div class="drag-button mt-3" v-if="content.grupos.length">
                    <v-btn @click="removerTodas()" color="primary" outlined small>
                      Remover Todos
                    </v-btn> 
                  </div>
                  <draggable class="drag-item" group="grupos" @change="verifyIncluidos($event)" v-model="content.grupos">
                    <v-card class="drag-card" v-for="(item, i) in content.grupos" :key="i">
                        <span>{{ item.nome }}</span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="remove(i)" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        Clique ou arraste para remover.
                      </v-tooltip>
                    </v-card>
                  </draggable>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <scae-buttons
            @click="salvar()"
            @cancel="$emit('onClose')"
            :actionText="'Salvar'"
          ></scae-buttons>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { DialogProps, Perfil } from '~/commons/classes';
  import draggable from "vuedraggable";
  
  export default {
    props: new DialogProps(Perfil),
    components: { draggable },
    computed: {
      getClassAuxiliarGrupos() {
        return !this.form.lists.grupos?.length
          ? 'drag-text-empty'
          : 'drag-text';
        },
      getClassAuxiliarGruposSelecionados() {
            return !this.content.grupos?.length
              ? 'drag-text-empty'
              : 'drag-text';
        }
    },
    data: () => ({
      form: {
        lists: {
          grupos: [],
        },
      }
    }),
    watch: {
      visible(v) {
        if (v) {
          this.buscarGrupos();
        } else {
          this.$emit("onClose");
        }
      }
    },
    methods: {
      salvar() {
        const save = () => {
          return this.$swal({
            text: `Deseja confirmar ${
              this.edit ? "a alteração" : "o cadastro"
            } do Perfil?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Cancelar`,
          }).then(() => {
            const payload = {
            ...this.content,
              perfilId: this.content.id,
              grupos: this.content.grupos.map(grupo => grupo.id),
            };
            return this.edit
              ? this.$atualizarPerfil(payload, this.content.id)
              : this.$cadastrarPerfil(new Perfil(payload));
          });
        };
        if (this.$validarFormulario()) {
          if (this.content.grupos.length) {
            save().then(({ data }) => {
              this.notifySuccess(`${data.mensagem}<br/><i>Para consolidar as alterações, é necessário sair e entrar novamente no sistema!</i>`);
              this.$emit("onClose");
              this.$emit("onSave");
            }).catch(error => {
              this.notifyError(error.response.data.mensagem);
            });
          } else this.notifyWarning("Informe um ou mais grupos associados");
        }
      },
      remove(index) {
        this.form.lists.grupos.push(this.content.grupos[index]);
        this.content?.grupos?.splice(index, 1);

        this.form.lists.grupos.sort((a, b) => a.nome.localeCompare(b.nome));
      },
      verify(event) {
        // Se estiver movendo um grupo para a lista de 'todos'
        if (event.added) {
          // Verifica se já existe dentro dessa lista esse grupo
          if (this.form.lists.grupos.filter(p => p.id == event.added.element.id).length > 1) {
            // Se sim, remove da lista para não duplicar
            this.form.lists.grupos?.splice(this.form.lists.grupos.findIndex(p => p.id == event.added.element.id), 1);
          }
        }
        this.form.lists.grupos.sort((a, b) => a.nome.localeCompare(b.nome));
        this.content.grupos.sort((a, b) => a.nome.localeCompare(b.nome));
      },
      verifyIncluidos(event) {
        // Se estiver movendo um grupo para a lista de incluidos
        if (event.added) {
          // Verifica se já existe dentro dessa lista essa grupo
          if (this.content.grupos.filter(p => p.id == event.added.element.id).length > 1) {
            // Se sim, remove da lista para não duplicar
            this.remove(this.content.grupos.findIndex(p => p.id == event.added.element.id));
          }
        }
        this.form.lists.grupos.sort((a, b) => a.nome.localeCompare(b.nome));
        this.content.grupos.sort((a, b) => a.nome.localeCompare(b.nome));
      },
      moverTodas() {
        const valoresFiltrados = new Set();
        this.form.lists.grupos.forEach(g => valoresFiltrados.add(g));
        this.content.grupos.forEach(g => valoresFiltrados.add(g));
        this.content.grupos = Array.from(valoresFiltrados);
        this.form.lists.grupos = [];
      },
      removerTodas() {
        this.content.grupos = [];
        this.buscarGrupos();
      },
      onEnterPressed() {
      // Evita a ação padrão do Enter
      },
      buscarGrupos() {
        this.$buscarGrupos({ params: { ativo: true } }).then(({ data }) => {
          const ids = this.content.grupos?.map((g) => g.id) ?? [];
          this.form.lists.grupos = data.listarGrupos.filter((g) => !ids.includes(g.id));
        });
      }
    },
    mounted() {
      this.buscarGrupos();
    }
  };
</script>

<style scoped>
.drag-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.drag-text-empty {
  display: none;
}

.border {
  border: 1px solid #9e9e9e;
  box-shadow: none !important;
  
}

.drag-card {
  cursor: grab;
  border: 1px solid #9e9e9e;
  box-shadow: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  padding: 10px;
  margin-bottom: 10px;
}

.drag-item {
  height: calc(100% - 4em);
}

.drag-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1em;
}
</style>
