<template>
  <div class="page">
    <v-container fluid>
      <scae-breadcrumbs></scae-breadcrumbs>
      <v-form ref="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="2" v-show="form.filters.analitico">
            <v-text-field
              label="CPF"
              v-model="form.filters.cpf"
              v-mask="'###.###.###-##'"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2" v-show="form.filters.analitico">
            <v-text-field
              label="Nome"
              v-model="form.filters.nome"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2" v-show="form.filters.analitico">
            <v-autocomplete
              label="Perfil"
              :items="form.lists.listPerfis"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="form.filters.perfil"
              outlined
              dense
            >
          </v-autocomplete>
          </v-col>
          <v-col cols="2" v-show="form.filters.analitico">
            <v-select
              label="Situação"
              :items="opcoesStatus"
              v-model="form.filters.situacao"
              item-text="descricao"
              item-value="value"
              id="situacao"
              dense
              outlined
              hide-details="auto"
            ></v-select>
          </v-col>
          <scae-switch-button
            v-model="form.filters.analitico"
            :cols="2"
            label="Tipo de Relatório"
            labelChecked="Analítico"
            labelUnchecked="Sintético"
            @click="resetForm()"
          ></scae-switch-button>
        </v-row>
        <div class="text-center">
          <v-col cols="12">
            <scae-tooltip
              :text="'Buscar'"
              :icon="'mdi-magnify'"
              @click="buscarRelatorios()"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Limpar'"
              :icon="'mdi-eraser'"
              @click="resetForm()"
            ></scae-tooltip>
          </v-col>
        </div>
      </v-form>
      <!-- Se o resultado da consulta de relatório for analítico -->
      <v-data-table
        v-show="form.filters.analitico"
        class="mt-5 printable"
        :headers="getFilteredHeader(headersAnalitico)"
        :items="form.lists.listRelatorios"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps"
        :show-expand="!onReport" 
        item-key="cpf"
        >
        <template v-slot:[`item.cpf`]="{ item }">
          <div class="report">
            {{ $formatCpf(item.cpf) }}
          </div>
        </template>
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.perfil`]="{ item }">
          {{ item.perfil.map((perfil) => perfil.nome).filter((value, index, self) => self.indexOf(value) === index).join(', ') }}
        </template>
        <template v-slot:[`item.situacao`]="{ item }">
          <scae-ativo-inativo :ativo="item.situacao"></scae-ativo-inativo>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="perfis" :colspan="headers.length">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                class="chip-permissao"
                v-for="(perfil, i) in item.perfil"
                :key="i"
              >
                <v-icon class="perfil" small>mdi-account-multiple</v-icon>
                {{ perfil.nome }}
              </v-chip>
            </v-chip-group>
          </td>
        </template>
      </v-data-table>
      <!-- Se o resultado da consulta de relatório for sintético -->
      <v-data-table
        v-show="!form.filters.analitico"
        class="mt-5 printable"
        :headers="getFilteredHeader(headersSintetico)"
        :items="form.lists.listRelatorios"
        :items-per-page="form.itemsPerPage"
        :footer-props="$footerProps"
      >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.quantidadeUsuarioSADRH }}
        </template>
        <template v-slot:[`item.quantidadeUsuarioSADRH`]="{ item }">
          {{ item.quantidadeUsuarioSADRH }}
        </template>
        <template v-slot:[`item.dataConsulta`]="{ item }">
          {{ item.dataConsulta }}
        </template>
        <template v-slot:[`item.cpf`]="{ item }">
          <div class="report">
              {{ $formatCpf(item.cpf) }}
          </div>
        </template>
      </v-data-table>
      <scae-buttons
        v-if="form.lists.listRelatorios.length"
        :actionText="'Baixar PDF'"
        :actionIcon="'mdi-printer'"
        :cancelText="'Baixar CSV'"
        :cancelIcon="'mdi-file'"
        @click="baixarRelatorioPdf()"
        @cancel="baixarRelatorioCsv()"
      ></scae-buttons>
    </v-container>
  </div>
</template>

<script>
  import pageLoadingMixin from "mixins/page-loading";

  export default {
    name: "ListarRelatoriosUsuariosView",
    mixins: [pageLoadingMixin],
    data: () => ({
      onReport: false,
      componentKey: 0,
      form: {
        filters: {
          nome: null,
          cpf: null,
          situacao: null,
          analitico: true,
          perfil: null,
        },
        lists: {
          listRelatorios: [],
          listPerfis: [],
        },
        itemsPerPage: 10,
        geradorRelatorio: null,
        horaDaGeracao: null,
        totalUsuarios: 0
      },
      opcoesStatus: [
        { value: true, descricao: "Ativo" },
        { value: false, descricao: "Inativo" },
      ],
      headersAnalitico: [
        { text: "CPF", align: "center", value: "cpf"},
        { text: "Nome", align: "center", value: "nome"},
        { text: "Perfil", align: "center", value: "data-table-expand", onlyView: true},
        { text: "Perfil", align: "center", value: "perfil", onlyReport: true},
        { text: "Situação", align: "center", value: "situacao", onlyView: true},
      ],
      headersSintetico: [
        { text: "Quantidade de Usuários SADRH", align: "center", value: "quantidadeUsuarioSADRH" },
        { text: "Quantidade de Usuários Não SADRH", align: "center", value: "quantidadeUsuarioNaoSADRH"},
        { text: "Data da Consulta", align: "center", value: "dataConsulta", onlyView: true },
      ],
    }),
    computed: {
      isAnalitico() {
        return this.form.filters.analitico;
      },
    },
    methods: {
      getFilteredHeader(headers) {
        return headers.filter((header) => {
          if (header.onlyView) return !this.onReport;
          if (header.onlyReport) return this.onReport;
          return true;
        });
      },
      buscarRelatorios() {
        if (this.$validarFormulario()) {
          this.form.filters.cpf = this.$unMask(this.form.filters.cpf);
          const preparedRequest = JSON.parse(JSON.stringify(this.form.filters));
          if (preparedRequest.dataFimTentativaLogin)
            preparedRequest.dataFimTentativaLogin = this.$formatarDateParaEnviar(
              preparedRequest.dataFimTentativaLogin,
              "23:59:59"
            );

          const buscarRelatorio = (params) => {
            return this.isAnalitico
              ? this.$obterRelatorioUsuarios(params)
              : this.$obterRelatorioUsuariosSintetico(params);
          };

          this.showPageLoading();

          try {
            buscarRelatorio(this.$montarParamsFilters(preparedRequest))
                .then(({ data }) => {
                    this.form.geradorRelatorio = data.relatorio.geradorRelatorio;
                    this.form.horaDaGeracao = data.relatorio.horaDaGeracao;
                    this.form.totalUsuarios = data.relatorio.totalUsuarios;
                    this.form.lists.listRelatorios = data.relatorio.usuarios;
                  })
                .then(() => this.hidePageLoading())
                .catch((err) => {
                  this.notifyError(err.message);
                  this.hidePageLoading();
                });
          } catch {
            this.hidePageLoading();
          }
        }
      },
      baixarRelatorioPdf() {
        const parametros = this.$montarParamsFilters(this.form.filters);

        const baixarPdf = (params) => {
          return this.isAnalitico
            ? this.$obterRelatorioPdfUsuarios(params)
            : this.$obterRelatorioPdfUsuariosSintetico(params);
        };

        const relatorioNome = this.isAnalitico
                ? "SCAE.RelatórioUsuáriosAnalítico.pdf"
                : "SCAE.RelatórioUsuárioSintético.pdf";

        this.showPageLoading();

        try {
          baixarPdf({ ...parametros, responseType: 'blob' })
            .then(({data}) => {
              const url = URL.createObjectURL(data);
              const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', relatorioNome);
                document.body.appendChild(link);
                link.click();
            })
            .then(() => this.hidePageLoading())
            .catch((err) => {
              this.notifyError(err.message);
              this.hidePageLoading();
            });
        } catch {
          this.showPageLoading();
        }
      },
      baixarRelatorioCsv() {
        const parametros = this.$montarParamsFilters(this.form.filters);

        const baixarRelatorio = (params) => {
          return this.isAnalitico
            ? this.$obterRelatorioCSVUsuarios(params)
            : this.$obterRelatorioCSVUsuariosSintetico(params);
        };

        const relatorioNome = this.isAnalitico
                ? "SCAE.RelatórioUsuárioAnalítico.csv"
                : "SCAE.RelatórioUsuárioSintético.csv";

        this.showPageLoading();
        try {
          baixarRelatorio({ ...parametros, responseType: 'blob' })
              .then(({ data }) => {
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(data);
                  link.setAttribute('download', relatorioNome);
                  document.body.appendChild(link);
                  link.click();
              })
              .then(() => this.hidePageLoading())
              .catch((err) => {
                this.notifyError(err.message);
                this.hidePageLoading();
              });
        } catch {
          this.hidePageLoading();
        }
      },
      resetForm() {
        this.form.lists.listRelatorios = [];

        this.$resetForm();
      }
    },
    mounted() {
      this.$obterPerfis().then(({ data }) => {
        this.form.lists.listPerfis = data.perfis;
      })
    }
  };
</script>
<style scoped>
.perfis {
  padding: 1em !important;
  text-align: center;
  font-weight: 500;
}
.perfil {
  padding-right: 0.5em;
}
</style>