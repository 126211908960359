import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c(VContainer,{attrs:{"fluid":""}},[_c('scae-breadcrumbs'),_c(VForm,{ref:"form"},[_c(VRow,{staticClass:"d-flex justify-center mt-3"},[_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"label":"Mantenedores","outlined":"","dense":""},model:{value:(_vm.form.filters.nome),callback:function ($$v) {_vm.$set(_vm.form.filters, "nome", $$v)},expression:"form.filters.nome"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getMask(_vm.form.filters.cpfCnpj)),expression:"getMask(form.filters.cpfCnpj)"}],attrs:{"label":"CPF/CNPJ","outlined":"","dense":""},model:{value:(_vm.form.filters.cpfCnpj),callback:function ($$v) {_vm.$set(_vm.form.filters, "cpfCnpj", $$v)},expression:"form.filters.cpfCnpj"}})],1),_c('scae-select-ativo',{attrs:{"cols":3},model:{value:(_vm.form.filters.ativo),callback:function ($$v) {_vm.$set(_vm.form.filters, "ativo", $$v)},expression:"form.filters.ativo"}})],1),_c('div',{staticClass:"text-center"},[_c(VCol,{attrs:{"cols":"12"}},[_c('scae-tooltip',{attrs:{"text":'Buscar',"icon":'mdi-magnify'},on:{"click":function($event){return _vm.buscarMantenedores()}}}),_c('scae-tooltip',{attrs:{"text":'Limpar',"icon":'mdi-eraser'},on:{"click":function($event){return _vm.resetForm()}}}),_c('scae-tooltip',{attrs:{"text":'Incluir',"icon":'mdi-plus'},on:{"click":function($event){return _vm.openDialogManterMantenedores()}}})],1)],1)],1),_c(VDataTable,{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.form.lists.listaMantendedores,"items-per-page":10,"footer-props":_vm.$footerProps},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome)+" ")]}},{key:"item.cpfCnpj",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"report"},[_vm._v(" "+_vm._s(_vm.$formatCpfCnpj(item.cpfCnpj))+" ")])]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('scae-label-ativo',{attrs:{"ativo":item.ativo}})]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('scae-tooltip',{attrs:{"text":'Visualizar Mantenedor',"icon":'mdi-eye-outline',"small":true},on:{"click":function($event){return _vm.openDialogVisualizarMantenedores(item)}}}),_c('scae-tooltip',{attrs:{"text":'Editar Mantenedor',"icon":'mdi-pencil',"small":true},on:{"click":function($event){return _vm.openDialogManterMantenedores(item)}}}),_c('scae-tooltip',{attrs:{"text":((item.ativo ? 'Inativar' : 'Ativar') + " Mantenedor"),"icon":item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch',"color":item.ativo ? 'blue' : 'red',"small":true},on:{"click":function($event){return _vm.inativarAtivarMantenedores(item)}}})]}}],null,true)})],1),_c('CadastrarEditarMantenedoresDialog',{attrs:{"visible":_vm.dialogManterMantenedores.visible,"content":_vm.dialogManterMantenedores.content,"edit":_vm.dialogManterMantenedores.edit},on:{"onClose":function($event){return _vm.dialogManterMantenedores.close()},"onSave":function($event){return _vm.buscarMantenedores()}}}),_c('VisualizarMantenedoresDialog',{attrs:{"visible":_vm.dialogVisualizarMantenedores.visible,"content":_vm.dialogVisualizarMantenedores.content},on:{"onClose":function($event){return _vm.dialogVisualizarMantenedores.close()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }