<template>
    <div class="page">
      <v-container fluid>
        <scae-breadcrumbs></scae-breadcrumbs>
        <v-form ref="form">
          <v-row class="d-flex justify-center mt-3">
            <v-col cols="3">
              <v-text-field
                label="Nome do grupo"
                v-model="form.filters.nome"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <scae-select-ativo
              :cols="3"
              v-model="form.filters.ativo"
            ></scae-select-ativo>
          </v-row>
          <div class="text-center">
            <v-col cols="12">
              <scae-tooltip
                :text="'Buscar'"
                :icon="'mdi-magnify'"
                @click="buscarGrupos()"
              ></scae-tooltip>
              <scae-tooltip
                :text="'Limpar'"
                :icon="'mdi-eraser'"
                @click="resetForm()"
              ></scae-tooltip>
              <scae-tooltip
                :text="'Incluir'"
                :icon="'mdi-plus'"
                @click="openDialogManterGrupo()"
              ></scae-tooltip>
            </v-col>
          </div>
        </v-form>
        <v-data-table
          class="mt-5"
          :headers="headers"
          :items="form.lists.listGrupos"
          :items-per-page="10"
          :footer-props="$footerProps"
          show-expand
        >
          <template v-slot:[`item.nome`]="{ item }">
            {{ item.nome }}
          </template>
          <template v-slot:[`item.nomeDeExibicao`]="{ item }">
            {{ item.nomeDeExibicao }}
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <scae-label-ativo :ativo="item.ativo"></scae-label-ativo>
          </template>
          <template v-slot:[`item.acoes`]="{ item }">
            <scae-tooltip
              :text="'Visualizar Grupo'"
              :icon="'mdi-eye-outline'"
              :small="true"
              @click="openDialogVisualizarGrupo(item)"
            ></scae-tooltip>
            <scae-tooltip
              :text="'Editar Grupo'"
              :icon="'mdi-pencil'"
              :small="true"
              @click="openDialogManterGrupo(item)"
            ></scae-tooltip>
            <scae-tooltip
              :text="`${item.ativo ? 'Inativar' : 'Ativar'} Grupo`"
              :icon="item.ativo ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch'"
              :color="item.ativo ? 'blue' : 'red'"
              :small="true"
              @click="inativarAtivarGrupo(item)"
            ></scae-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td class="permissoes" :colspan="headers.length">
              <v-chip-group active-class="primary--text" column>
                <v-chip
                  class="chip-permissao"
                  v-for="(permissao, i) in item.permissoes"
                  :key="i"
                >
                  <v-icon class="permissao" small
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  {{ permissao.nome }}
                </v-chip>
              </v-chip-group>
            </td>
          </template>
        </v-data-table>
      </v-container>
       <CadastrarEditarGrupoDialog
        :visible="dialogManterGrupo.visible"
        :content="dialogManterGrupo.content"
        :edit="dialogManterGrupo.edit"
        @onClose="dialogManterGrupo.close()"
        @onSave="buscarGrupos()"
      ></CadastrarEditarGrupoDialog>
      <VisualizarGrupoDialog
        :visible="dialogVisualizarGrupo.visible"
        :content="dialogVisualizarGrupo.content"
        @onClose="dialogVisualizarGrupo.close()"
      ></VisualizarGrupoDialog>
    </div>
  </template>
  
  <script>
  import { Dialog, Grupo } from "~/commons/classes";
  import CadastrarEditarGrupoDialog from "./dialogs/CadastrarEditarGrupoDialog.vue";
  import VisualizarGrupoDialog from "./dialogs/VisualizarGrupoDialog.vue";
  
  export default {
    name: "ManterGrupoView",
    components: {
      CadastrarEditarGrupoDialog,
      VisualizarGrupoDialog,
    },
    data: () => ({
      dialogManterGrupo: Dialog.Build(),
      dialogVisualizarGrupo: Dialog.Build(),
      form: {
        filters: {
          nome: null,
          ativo: null,
        },
        lists: {
          listGrupos: []
        }
      },
      headers: [
        { text: "Nome do grupo", align: "center", value: "nome" },
        { text: "Nome de exibição", align: "center", value: "nomeDeExibicao" },
        { text: "Ativo", align: "center", value: "ativo" },
        { text: "Permissões", align: "center", value: "data-table-expand" },
        { text: "Ações", align: "center", value: "acoes", sortable: false },
      ]
    }),
    methods: {
      openDialogManterGrupo(grupo) {
        grupo
          ? this.$visualizarGrupos(grupo.id).then(({ data }) => {
              this.dialogManterGrupo.open(new Grupo(data.obterGrupos).format(), true);
            })
          : this.dialogManterGrupo.open(Grupo.Build());
      },
      openDialogVisualizarGrupo(grupo) {
        this.$visualizarGrupos(grupo.id).then(
          ({ data }) => (this.dialogVisualizarGrupo.open(new Grupo(data.obterGrupos)))
        );
      },
      buscarGrupos() {
        this.$buscarGrupos({ params: this.form.filters }).then(
          ({ data }) => (this.form.lists.listGrupos = data.listarGrupos)
        );
      },
      inativarAtivarGrupo(grupo) {
        this.$swal({
          text: `Deseja confirmar a ${
            grupo.ativo ? "inativação" : "ativação"
          } do Grupo?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          this.$inativarAtivarGrupo({ grupoId: grupo.id, ativo: !grupo.ativo }, grupo.id)
              .then(({ data }) => {
                this.notifySuccess(`${data.mensagem}<br/><i>Para consolidar as alterações, é necessário sair e entrar novamente no sistema!</i>`);
                this.buscarGrupos();
              })
              .catch(({ response }) => { 
                if (response.status == 403) {
                  this.notifyError("Usuário não possui permissão para realizar a ação.");
                }
              });
        });
      },
      resetForm() {
        this.$resetForm();
        this.form.lists.listGrupos = [];
      },
    },
  };
  </script>
  <style scoped>
  .permissoes {
    padding: 1em !important;
    text-align: center;
    font-weight: 500;
  }
  .permissao {
    padding-right: 0.5em;
  }
  </style>